export const data = {
    "status": "success",
    "message": "",
    "data": [
        {
            "id": "1",
            "status": "ongoing",
            "name": "Mr. Johnson",
            "role": "Endocrinologist",
            "difficulty": "Medium",
            "domain": "Healthcare",
            "image_id": "M2",
            "topic": "Pricing Negotiation",
            "objective": "Deepen Relationship"
        },
        {
            "id": "2",
            "status": "completed",
            "name": "Mr. Oman Sen Gupta",
            "role": "Bank Manager",
            "difficulty": "Medium",
            "domain": "Finance",
            "image_id": "M1",
            "topic": "Introduction and Product benefit",
            "objective": "Invite to a meeting or event"
        },
        {
            "id": "3",
            "status": "ongoing",
            "name": "Mr. Johnson",
            "role": "Endocrinologist",
            "difficulty": "Medium",
            "domain": "Healthcare",
            "image_id": "M2",
            "topic": "Pricing Negotiation",
            "objective": "Deepen Relationship"
        },
        {
            "id": "2",
            "status": "completed",
            "name": "Mr. Oman Sen Gupta",
            "role": "Bank Manager",
            "difficulty": "Medium",
            "domain": "Finance",
            "image_id": "M1",
            "topic": "Introduction and Product benefit",
            "objective": "Invite to a meeting or event"
        },
        {
            "id": "1",
            "status": "ongoing",
            "name": "Mr. Johnson",
            "role": "Endocrinologist",
            "difficulty": "Medium",
            "domain": "Healthcare",
            "image_id": "M2",
            "topic": "Pricing Negotiation",
            "objective": "Deepen Relationship"
        },
        {
            "id": "12",
            "status": "completed",
            "name": "Mr. Oman Sen Gupta",
            "role": "Bank Manager",
            "difficulty": "Medium",
            "domain": "Finance",
            "image_id": "M1",
            "topic": "Introduction and Product benefit",
            "objective": "Invite to a meeting or event"
        },
        {
            "id": "1",
            "status": "ongoing",
            "name": "Mr. Johnson",
            "role": "Endocrinologist",
            "difficulty": "Medium",
            "domain": "Healthcare",
            "image_id": "M2",
            "topic": "Pricing Negotiation",
            "objective": "Deepen Relationship"
        },
        {
            "id": "2",
            "status": "completed",
            "name": "Mr. Oman Sen Gupta",
            "role": "Bank Manager",
            "difficulty": "Medium",
            "domain": "Finance",
            "image_id": "M1",
            "topic": "Introduction and Product benefit",
            "objective": "Invite to a meeting or event"
        },
        {
            "id": "11",
            "status": "ongoing",
            "name": "Mr. Johnson",
            "role": "Endocrinologist",
            "difficulty": "Low",
            "domain": "Healthcare",
            "image_id": "M2",
            "topic": "Pricing Negotiation",
            "objective": "Deepen Relationship"
        },
        {
            "id": "2",
            "status": "completed",
            "name": "Mr. Oman Sen Gupta",
            "role": "Bank Manager",
            "difficulty": "Medium",
            "domain": "Finance",
            "image_id": "M1",
            "topic": "Introduction and Product benefit",
            "objective": "Invite to a meeting or event"
        },
        {
            "id": "1",
            "status": "ongoing",
            "name": "Mr. Johnson",
            "role": "Endocrinologist",
            "difficulty": "Low",
            "domain": "Healthcare",
            "image_id": "M2",
            "topic": "Pricing Negotiation",
            "objective": "Deepen Relationship"
        },
        {
            "id": "2",
            "status": "completed",
            "name": "Mr. Oman Sen Gupta",
            "role": "Bank Manager",
            "difficulty": "Medium",
            "domain": "Finance",
            "image_id": "M1",
            "topic": "Introduction and Product benefit",
            "objective": "Invite to a meeting or event"
        },
        {
            "id": "1",
            "status": "ongoing",
            "name": "Mr. Johnson",
            "role": "Endocrinologist",
            "difficulty": "High",
            "domain": "Healthcare",
            "image_id": "M2",
            "topic": "Pricing Negotiation",
            "objective": "Deepen Relationship"
        },
        {
            "id": "22",
            "status": "completed",
            "name": "Mr. Oman Sen Gupta",
            "role": "Bank Manager",
            "difficulty": "High",
            "domain": "Finance",
            "image_id": "M1",
            "topic": "Introduction and Product benefit",
            "objective": "Invite to a meeting or event"
        }
    ]
} 