export enum ApiStatus {
  Success = "success",
  Failed = "failed",
}

export enum AppRoutes {
  Dashboard = "chat",
  Login = "login",
  UserDashbaord = "dashboard",
}

export enum Icons {
  customer = "customer",
  difficulty = "difficulty",
  personality_type = "personality_type",
  topic = "topic",
}

export const maleVoices = ["echo", "onyx"];
export const femaleVoices = ["nova", "shimmer", "fable"];
export const audioSpeed = 1.15;
export const notifications = [
  "Evaluation is in progress. Reports will be generated soon.",
  "Report generation in progress.",
  "The process might take sometime",
];
export const reportsScoreValues = ["Needs Improvement", "Good", "Great"];
