import React, { useEffect } from "react";
import './chat-window.scss';
import { MuiSwitch } from "../../../shared/components/mui/switch";
import { Box, Button, Typography } from "@mui/material";
import { AddIcon } from "../../../../assets/icons/Icons";
import { useNavigate } from "react-router-dom";
import { Chat } from "../chat/chat";
import { ChatReport } from "../chat-report/chat-report";
import { useTrainer } from "../../services/trainer.service";
import { useAuth } from "../../../../utils/auth/auth.guard";
import { BackIcon } from "../../../../assets/icons/Icons";
import { MuiButton } from "../../../shared/components/mui/button";

export default function ChatWindow({checked, setChecked}: any) {
    const navigate = useNavigate();
    const trainer = useTrainer();
    const auth = useAuth();

    const [mode, setMode] = React.useState("");
    useEffect(() => {
        setMode(
            trainer.selectedChat
                ? trainer.selectedChat.status === "completed"
                    ? "report"
                    : "ongoing-chat"
                : "new"
        );
    }, [trainer.selectedChat])

    return (
        <Box className="chat-window">
            <Box className="window-top main-view">
                <Typography component="h6">
                    Chat Window
                    {
                        trainer.selectedChat
                            ? <span className="ms-4">{trainer.selectedChat.name} | {trainer.selectedChat.personality_type} | {trainer.selectedChat.topic}</span>
                            : null
                    }
                </Typography>
                <Box>
                    <Button className="addIcon-btn" variant="text" onClick={() => navigate("/dashboard/new-conversation")}>
                        New Conversation
                    </Button>
                </Box>
            </Box>
            <Box className="mobile-view page-title">
                <MuiButton
                    variant="text"
                    startIcon={<BackIcon />}
                    onClick={() => trainer.setSelectedChat(null)}
                    className="back-link"
                >
                </MuiButton>
                <span className="profile-text">
                    <small className="me-2"></small>    {
                        trainer.selectedChat
                            ? <span>{trainer.selectedChat.name} | {trainer.selectedChat.personality_type} | {trainer.selectedChat.topic}</span>
                            : null
                    }</span>

                <Button className="new-btn" variant="text" endIcon={<AddIcon />} onClick={() => navigate("/dashboard/new-conversation")}>
                    New Conversation
                </Button>

            </Box>
            <Box className="window-middle">
                {
                    trainer.selectedChat && trainer.selectedChat.status === "completed"
                        ? (
                            <Box className="top-switch">
                                <MuiSwitch className="ms-auto" startLabel="Chats" endLabel="Scorecard" checked={mode === "report"} onValueChange={(v) => { setMode(v ? "report" : "chat") }} />
                            </Box>
                        )
                        : null
                }
                {
                    mode === "new"
                        ? (
                            <Box className="window-content flex-grow-1">
                                <div className=" d-flex align-items-center justify-content-center flex-column mb-5 pb-5">
                                    <Typography component="h2">
                                        Welcome {auth.userDetails?.firstName}!
                                    </Typography>
                                    <Typography component="small">Ready? Chat. Train! Click on a chat to the left to see a previous conversation</Typography>
                                    <Button variant="text" onClick={() => navigate("/dashboard/new-conversation")}>
                                        <span className="add-icon"></span>
                                        Start a new conversation
                                    </Button>
                                </div>
                            </Box>
                        )
                        : null
                }
                {
                    mode.includes("chat")
                        ? <Chat checked={checked}  setChecked={setChecked} />
                        // ? <ChatReport />
                        : null
                }
                {
                    mode === "report"
                        ? <ChatReport />
                        : null
                }
            </Box>
        </Box>
    )
}