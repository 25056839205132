export const data = {
  status: "success",
  message: "succeeded",
  data: [
    {
      key: "Nov22",
      value: null,
    },
    {
      key: "Dec22",
      value: 79,
    },
    {
      key: "Jan23",
      value: null,
    },
    {
      key: "Feb23",
      value: null,
    },
    {
      key: "Mar23",
      value: null,
    },
    {
      key: "Apr23",
      value: 68,
    },
    {
      key: "May23",
      value: 52,
    },
    {
      key: "Jun23",
      value: 54,
    },
    {
      key: "Jul23",
      value: 45.5,
    },
    {
      key: "Aug23",
      value: null,
    },
    {
      key: "Sep23",
      value: 50,
    },
    {
      key: "Oct23",
      value: 60,
    },
  ],
};
