export const data = {
  en: {
    new_conversation: "New Conversation",
    back: "Back",
    customer: "Customer",
    customer_id: "Customer",
    name: "Customer",
    next: "Next",
    previous: "Previous",
    difficulty: "Difficulty",
    personality_type: "Personality Type",
    begin_simulation: "Begin Simulation",
    group_by: "Group By",
    ongoing_chats: "Ongoint Chats",
    evaluated_chats: "Evaluated  Chats",
    chat_window: "Chat Window",
    easy: "Easy",
    hard: "Hard",
    medium: "Medium",
    random: "Random",
    introduction_and_product_benefit: "Introduction and Product benefit",
    end_and_evaluate: "End & Evaluate",
    type_your_response_here: "Type your response here |",
    start_new_conversation: "Start a new conversation",
    welcome: "Welcome",
    ready_chat:
      "Ready? Chat. Train! Click on a chat to the left to see a previous conversation",
    quick_actions: "Quick Actions",
    existing_conversation: "Existing Conversation",
    existing: "Existing",
    convo: "Conversation",
    score_card: "Here is your Scorecard",
    trend: "Trend over selected period",
    score: "Score",
    time_period: "Time period",
    average_score: "Average Score",
    overall: "Overall",
    tone: "Tone",
    on_label: "On-label recommendations",
    team_reference: "Appropriate team reference/handoff",
    objectives: "Meets conversation objectives",
    pie_chart: "No data available for the PieChart",
    start_new: "Start New",
    recommnedation: "Recommendation",
    na: "N/A",
    yes: "Yes",
    no: "No",
    of: "of",
    totalScore: "Total Score",
    chats: "Chats",
    scorecard: "Scorecard",
    evaluationCompleted: "Evaluation completed",
    language: "Language",
    lastUpdated: "Last Updated",
  },
  jp: {
    new_conversation: "新しい会話",
    back: "戻る",
    customer: "顧客",
    customer_id: "顧客",
    name: "顧客",
    next: "次",
    previous: "前に戻る",
    difficulty: "難易度",
    personality_type: "Personality Type",
    begin_simulation: "シミュレーションを開始する",
    group_by: "フィルター",
    ongoing_chats: "会話中途中のチャット",
    evaluated_chats: "評価されたチャット",
    chat_window: "チャットウィンドウ",
    easy: "簡単",
    hard: "難しい",
    medium: "中くらい",
    random: "ランダム",
    introduction_and_product_benefit: "概要と製品の利点",
    end_and_evaluate: "終了と評価",
    type_your_response_here: "ここに回答を入力してください |",
    start_new_conversation: "新しい会話を開始する",
    welcome: "ようこそ",
    sir: "さん",
    ready_chat:
      "準備はできましたか？新しく会話を始めてみましょう！過去の会話を参照する場合は、左側のチャットを選択してください。",
    quick_actions: "クイックアクション",
    existing_conversation: "既存の会話",
    existing: "既存",
    convo: "会話",
    score_card: "こちらがスコアカードです。",
    trend: "選択した期間の傾向",
    score: "スコア",
    time_period: "期間",
    average_score: "平均点",
    overall: "全体",
    tone: "トーン",
    on_label: "ラベル上の推奨事項",
    team_reference: "適切なチームの紹介/引き継ぎ",
    objectives: "会話の目的を達成する",
    pie_chart: "PieChart に使用できるデータがありません",
    start_new: "新しく始める",
    recommnedation: "おすすめ",
    na: "「該当なし」",
    yes: "はい",
    no: "いいえ",
    of: "の",
    totalScore: "合計スコア",
    chats: "チャット",
    scorecard: "スコアカード",
    evaluationCompleted: "評価完了",
    language: "言語",
    lastUpdated: "最終更新",
  },
};
