export const data = {
    "status": "success",
    "message": "succeeded",
    "data": [
        // {
        //     "label": "Topic Details",
        //     "key": "topic",
        //     "icon": "topic",
        //     "data": [
        //         {
        //             "title": "Domain",
        //             "key": "domain",
        //             "options": [
        //                 {
        //                     "title": "Healthcare",
        //                     "value": "1"
        //                 },
        //                 {
        //                     "title": "Insurance",
        //                     "value": "2"
        //                 },
        //                 {
        //                     "title": "Supply Chain",
        //                     "value": "3"
        //                 },
        //                 {
        //                     "title": "Finance",
        //                     "value": "4"
        //                 }
        //             ]
        //         },
        //         {
        //             "title": "Topic",
        //             "key": "topic",
        //             "options": [
        //                 {
        //                     "title": "Pricing Negotiation",
        //                     "value": "1"
        //                 },
        //                 {
        //                     "title": "Introduction and Product benefit",
        //                     "value": "2"
        //                 },
        //                 {
        //                     "title": "Contract Negotiation",
        //                     "value": "3"
        //                 }
        //             ]
        //         },
        //         {
        //             "title": "Objective",
        //             "key": "objective",
        //             "options": [
        //                 {
        //                     "title": "Achieve Favourable pricing",
        //                     "value": "1"
        //                 },
        //                 {
        //                     "title": "Invite to a meeting or event",
        //                     "value": "2"
        //                 },
        //                 {
        //                     "title": "Deepen Relationship",
        //                     "value": "3"
        //                 }
        //             ]
        //         }
        //     ]
        // },
       
        

        {
            "label": "Difficulty",
            "key": "difficulty",
            "icon": "difficulty",
            "data": [
                {
                    "cardType": "normal",
                    "title": "Difficulty",
                    "key": "difficulty",
                    "options": [
                        {
                            "title": "Easy",
                            "value": "1"
                        },
                        {
                            "title": "Medium",
                            "value": "2"
                        },
                        {
                            "title": "Hard",
                            "value": "3"
                        },
                        {
                            "title": "Random",
                            "value": "Random"
                        }
                    ]
                }
            ]
        },
        {
            "label": "Customer Details",
            "key": "customer_id",
            "icon": "customer",
            "data": [
                {
                    "cardType": "customer",
                    "title": "Customer",
                    "key": "customer",
                    "options": [
                        {
                            "image_id": "M1",
                            "name": "Dr. Johnson Paul",
                            "role": "Endocrinologist",
                            "description": "Mr. Johnson is an endocrinologist that's been practicing medicine for over 10 years. Lorem Ipsum Mr. Johnson is an endocrinologist that’s been practicing medicine for over 10 years. Mr. Johnson is an endocrinologist that's been practicing medicine for over 10 years.",
                            "value": "1"
                        },
                        {
                            "image_id": "M2",
                            "name": "Dr. Smith Stones",
                            "role": "Endocrinologist",
                            "description": "",
                            "value": "2"
                        },
                        {
                            "image_id": "F1",
                            "name": "Dr. Pamella Amber",
                            "role": "Endocrinologist",
                            "description": "",
                            "value": "3"
                        },
                        {
                            "image_id": "F2",
                            "name": "Dr. Carol Mc'well",
                            "role": "Endocrinologist",
                            "description": "",
                            "value": "4"
                        },
                        {
                            "image_id": "M3",
                            "name": "Mr. Oman Sen Gupta",
                            "role": "Endocrinologist",
                            "description": "",
                            "value": "5"
                        }
                    ]
                }
            ]
        },
    ]
}