import React from 'react';
import {
	LoginPage
} from "./index"
import RouterModule from '../../utils/auth/router.module';
import { Navigate } from 'react-router-dom';
import Protected from '../../utils/auth/route.guard';

export const routes = () => {
	return (

		<RouterModule routes={
		[
			{
				path: "",
				element: <Protected><LoginPage /></Protected>,
			},
			{
				path: "*",
				element: <Navigate to={`/login`} />,
			},
		]
	} />

	)
}