import React from "react";
import "./dashbaord.scss";
import {
  Box,
  MenuItem,
  FormControl,
  Select,
  RadioGroup,
  FormControlLabel,
  Radio,
  Typography,
  CircularProgress,
} from "@mui/material";
import { Services } from "../../../shared/services";
import { useAuth } from "../../../../utils/auth/auth.guard";
import { useNavigate } from "react-router";
import {
  DASHBAORD_FILTER_RESPONSE,
  DASHBAORD_RECOMMENDATION_DATA,
  DASHBOARD_TREND_CHART,
} from "../../../../assets/data/json";
import { DASHBOARD_CHART_SCORES } from "../../../../assets/data/json";
import {
  IFilterData,
  IRecommendation,
  chartData,
  chartDataResponse,
} from "../../../../utils/interfaces/interface";
import { PieChart } from "@mui/x-charts/PieChart";
import { ResponsiveChartContainer } from "@mui/x-charts/ResponsiveChartContainer";
import { LinePlot, MarkPlot } from "@mui/x-charts/LineChart";
import { ChartsXAxis } from "@mui/x-charts/ChartsXAxis";
import { ChartsYAxis } from "@mui/x-charts/ChartsYAxis";
import { ChartsReferenceLine } from "@mui/x-charts/ChartsReferenceLine";
import { ConversionIcon, NextArrow } from "../../../../assets/icons/Icons";
import { AddConversionIcon } from "../../../../assets/icons/Icons";
import { ChartsTooltip } from "@mui/x-charts";
import { useTrainer } from "modules/trainer/services/trainer.service";
import { reportsScoreValues } from "utils/enums/enum";

export function NewUserDashboard() {
  const AppService = React.useContext(Services.AppService);
  const auth = useAuth()!;
  const navigate = useNavigate();

  const trainer = useTrainer();
  const [loading, setLoading] = React.useState(false);
  const [sectionLoading, setSectionLoading] = React.useState(false);
  const [trendSectionLoading, setTrendSectionLoading] = React.useState(false);
  const [filterData, setFilterData] = React.useState<any[]>();
  const [recommendationData, setRecommendationData] = React.useState<IRecommendation>();
  const [filtervalue, setFilterValue] = React.useState<any>();
  const [chartData, setChartData] = React.useState<any>();
  const [radioValue, setRadioValue] = React.useState("");
  const [radioScore, setRadioScore] = React.useState(Number);

  const colors = [
    "#D0527C",
    "#FFED48",
    "#43ACD9",
    "#76D45E",
    "#8B45D0",
    "#3865DA",
  ];

  const handleChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    score: any
  ) => {
    setRadioValue((event.target as HTMLInputElement).value as string);
    setRadioScore(score);
  };

  const getFilterConfigData = async () => {
    setLoading(true);
    AppService.API_HANDLER({
      auth,
      requestMethod: "get",
      url: "https://api.vrt.zsservices.com/get-filters",
      useGlobalLoader: true,
      showErrorMessage: true,
      showSuccessMessage: false,
      fetchFromApi: true,
      LOCAL_RESPONSE: DASHBAORD_FILTER_RESPONSE,
      successCb: (res: any) => {
        setFilterData(res.data);
        setLoading(false);
      },
      failedCb: () => {
        setLoading(false);
      },
    });
  };

  const getChartScoresData = async () => {
    setSectionLoading(true);
    AppService.API_HANDLER({
      auth,
      requestMethod: "post",
      payload: {
        // time_frame: filtervalue?.time_frame,
        customer_id: filtervalue?.customer_id,
        personality_type: filtervalue?.personality_type,
        language:trainer.language
      },
      url: "https://api.vrt.zsservices.com/chat-scores",
      useGlobalLoader: false,
      showErrorMessage: true,
      showSuccessMessage: false,
      fetchFromApi: true,
      LOCAL_RESPONSE: DASHBOARD_CHART_SCORES,
      successCb: (res: any) => {
        // console.log(res)
        res.data.forEach((v: any, i: number) => {
          v.color = colors[i % colors.length];
          v.value = v.score;
        });
        
        setChartData((prevState: any) => ({...prevState , donut_chart: res.data}));
        // console.log(res.data[0])
        setRadioValue(res.data[0]["title"]);
        setRadioScore(res.data[0]["score"]);
        setSectionLoading(false);
        
      },
      failedCb: () => {
        setSectionLoading(false);
      },
    });
  };
  const getTrendChartData = async () => {
    setTrendSectionLoading(true);
    AppService.API_HANDLER({
      auth,
      requestMethod: "post",
      payload: {
        customer_id: filtervalue?.customer_id,
        personality_type: filtervalue?.personality_type,
        topic : radioValue,
        language:filtervalue?.language
      },
      url: "https://api.vrt.zsservices.com/get-trend-chart",
      useGlobalLoader: false,
      showErrorMessage: true,
      showSuccessMessage: false,
      fetchFromApi: true,
      LOCAL_RESPONSE: DASHBOARD_TREND_CHART,
      successCb: (res: any) => {
        setChartData((prevState: any) => ({ ...prevState, trend_chart: res.data }));
        setTrendSectionLoading(false);

      },
      failedCb: () => {
        setTrendSectionLoading(false);
      },
    });
  };
  // const getRecommendationData = async () => {
  //   AppService.API_HANDLER({
  //     auth,
  //     requestMethod: "get",
  //     url: "https://virtual-rep-trainer-fastapi.atlas.zssandbox.com/recommendation",
  //     useGlobalLoader: true,
  //     showErrorMessage: true,
  //     showSuccessMessage: false,
  //     fetchFromApi: false,
  //     LOCAL_RESPONSE: DASHBAORD_RECOMMENDATION_DATA,
  //     successCb: (res: any) => {
  //       setRecommendationData(res);
  //     },
  //   });
  // };

  React.useEffect(() => {
    getFilterConfigData();
    // getRecommendationData();
  }, []);

  React.useEffect(() => {
    if (filterData) {
      // console.log(filterData)
      if (filterData.length > 0) {
        let filterValues: any = {
          language:trainer.language
        };
        filterData.forEach((filter: any) => {
          if (filter.key !='language') {
            filterValues[filter.key] = filter.options.filter((option: any) => (option.language ? option.language : 'en') == trainer.language)[0]?.value
          }
        })
        setFilterValue(filterValues);
      }
    }
  }, [filterData]);

  React.useEffect(() => {
    if (filtervalue && auth.authed) {
      getChartScoresData();
      if (radioValue.length) {
        getTrendChartData();
      }
    }
  }, [filtervalue, auth.authed]);

  React.useEffect(() => {
    if (chartData?.donut_chart) {
      // setRadioValue(chartData.donut_chart[0]["title"]);
      // setRadioScore(chartData.donut_chart[0]["score"]);
    }
  }, [chartData]);
  React.useEffect(() => {
    if (filtervalue && radioValue.length) {
      getTrendChartData();
    }
    // console.log(radioValue)
  }, [radioValue]);

  // Event handler for difficulty selection change
  const handleFilterChange = (
    event: React.ChangeEvent<{ value: unknown }>,
    key: string
  ) => {
    if (filtervalue) {
      let newFilters = { ...filtervalue };
      // console.log(newFilters)
      switch (key) {
        case "customer_id":
          newFilters.customer_id = (event.target.value as string) || "";
          break;
        case "personality_type":
          newFilters.personality_type = (event.target.value as string) || "";
          break;
        case "language":
          newFilters.language = (event.target.value as string) || "";
          // if (trainer.language != filtervalue.language) {
            trainer.setLanguage(newFilters.language);
          // }
          filterData?.forEach((filter: any) => {
            if (filter.key !='language') {
              newFilters[filter.key] = filter.options.filter((option: any) => option.language == newFilters.language)[0]['value']
            }
          })
          setRadioValue("");
          break;
      }
      setFilterValue(newFilters);
    }
  };

  function RenderPieChart({ data, selectedValue }: any) {
    let filteredData = data.donut_chart
      .filter((item: { title: any }) => item.title === selectedValue)
      .map((item: { score: any; title: any; color: any }) => ({
        value: item.score,
        label: item.title,
        color: item.color,
      }));

    if (filteredData && filteredData.length > 0) {
      filteredData.push({
        value: 100 - filteredData[0].value,
        color: "#1C1C1C",
      });
    }

    return (
      <PieChart
        series={[
          {
            data: filteredData,
            innerRadius: 70,
            outerRadius: 100,
            paddingAngle: 0,
            startAngle: -90,
            endAngle: 360,
            cx: 100,
            cy: 100,
          },
        ]}
        slotProps={{ legend: { hidden: true } }}
        width={210}
        height={210}
      />
    );
  }

  return (
    <>
      {!loading && auth.authed ? (
        <>
          <Box className="row home-page mx-0">
            <Box className="col-8 left-sidebar main-view">
              <div className="container-fluid py-3 mt-1">
                <h2 className="title mb-3 pb-1">
                  <strong> {trainer.translateData[trainer.language] ? trainer.translateData[trainer.language].welcome : ''} {auth.userDetails?.firstName}{trainer.language == 'jp' && ' ' + trainer.translateData[trainer.language].sir}! </strong> 
                  {trainer.translateData[trainer.language] ? trainer.translateData[trainer.language].score_card : ''}
                </h2>
                <Box className="d-flex mb-4">
                  {filterData?.map((filter:any, index: number) => (
                    <div key={index} className="me-3">
                      <label className="form-label"> {trainer.translateData[trainer.language][filter.key]} </label>
                      <FormControl>
                        <Select
                          value={filtervalue ? filtervalue[filter.key] : ""}
                          onChange={(event) => {
                            handleFilterChange(
                              event as React.ChangeEvent<{ value: unknown }>,
                              filter.key
                            );
                          }}
                        >
                          {filter.options.filter((option: any) => filter.key != 'language' ? (option.language ? option.language : 'en') == trainer.language : true).map((option: any, i: number) => (
                            <MenuItem key={i} value={option.value}>
                              {" "}
                              {option.title}{" "}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </div>
                  ))}
                </Box>
                {!(chartData?.donut_chart && chartData.donut_chart.filter((data: any) => data.score != null).length) 
                    ? <div className="me-3">
                        <h5 className="mb-0">{`*Please complete some interactions with ${filterData?.find((filter: any) => filter.key == 'customer_id').options.find((option: any) => option.value == filtervalue?.customer_id)?.title} for ${filtervalue?.personality_type} personality`}</h5>
                      </div> 
                    : <></>
                }
              </div>
              <div className={sectionLoading ? "position-relative" : ""}>
                {!sectionLoading ? (
                  <>
                    <div className="d-flex row border-bottom border-top">
                      <div className="py-4 d-flex">
                        <Box className="col-8 px-40">
                          {chartData?.donut_chart && chartData.donut_chart.map((data: any) => (
                            <FormControl
                              key={data.title}
                              className={`progress-list ${data.title === radioValue ? "active-item" : ""
                                }`}
                            >
                              <RadioGroup
                                aria-labelledby="demo-controlled-radio-buttons-group"
                                name="controlled-radio-buttons-group"
                                value={radioValue}
                                onChange={(event) =>
                                  handleChange(event, data.score)
                                }
                              >
                                <FormControlLabel
                                  value={data.title}
                                  control={<Radio />}
                                  label={data.title}
                                />
                                <div className="custom-bar progress">
                                  <div className="progress">
                                    <div
                                      className="progress-bar"
                                      role="progressbar"
                                      style={{
                                        width: `${data.score ? data.score : 0}%`,
                                        backgroundColor: data.color,
                                      }}
                                      aria-valuenow={data.score ? data.score : 0}
                                      aria-valuemin={0}
                                      aria-valuemax={100} // Update this to be a number
                                    >
                                      {/* {`${data.score != null ? data.score < 60 ? reportsScoreValues[0] : data.score < 80 ? reportsScoreValues[1] : reportsScoreValues[2] +'%':  (trainer.translateData[trainer.language] ? trainer.translateData[trainer.language].na : '')}`.split(' ').map((word: string, i: number) => (
                                        <span style={{right:trainer.language == 'jp' ? -25:i*(-75),}} >{`${word}`}</span>
                                      ))} */}
                                      <span title={`${data.score != null ? data.score < 60 ? reportsScoreValues[0] : data.score < 80 ? reportsScoreValues[1] : reportsScoreValues[2]:  (trainer.translateData[trainer.language] ? trainer.translateData[trainer.language].na : '')}`} >
                                        {`${data.score != null ? data.score < 60 ? reportsScoreValues[0] : data.score < 80 ? reportsScoreValues[1] : reportsScoreValues[2]:  (trainer.translateData[trainer.language] ? trainer.translateData[trainer.language].na : '')}`}</span>
                                    </div>
                                  </div>
                                </div>
                              </RadioGroup>
                            </FormControl>
                          ))}
                        </Box>
                        <Box className="col-4 d-flex justify-content-center align-items-center border-start pe-none">
                          <span className="text-center dount-text">
                            <small className="d-block">{radioValue}</small>
                            <span className="d-block">{radioScore != null ? radioScore < 60 ? reportsScoreValues[0] : radioScore < 80 ? reportsScoreValues[1] : reportsScoreValues[2]:  (trainer.translateData[trainer.language] ? trainer.translateData[trainer.language].na : '')}</span>
                          </span>
                          {chartData?.donut_chart &&
                            chartData.donut_chart.length > 0 ? (
                            <>
                              <RenderPieChart
                                data={chartData}
                                selectedValue={radioValue ? radioValue : 0}
                              />
                            </>
                          ) : (
                            <Typography variant="body2" color="text.secondary">
                              {trainer.translateData[trainer.language] ? trainer.translateData[trainer.language].pie_chart : ''}.
                            </Typography>
                          )}
                        </Box>
                      </div>
                    </div>
                    <div className={`row mt-3 pt-1 ${trendSectionLoading ? "position-relative graph-loader" : ""}`}>
                    {
                      !trendSectionLoading ? (
                        
                          <div className="col-12">
                            <div className="px-40">
                              <div className="d-flex align-items-center justify-content-between">
                                <h5 className="mb-0">{trainer.translateData[trainer.language] ? trainer.translateData[trainer.language].trend : ''}</h5>
                              </div>
                              <div className="d-flex justify-content-end">
                                <label className="legend me-4">
                                  <span
                                    className="score-legend"
                                    style={{ backgroundColor: "#FF9C80" }}
                                  ></span>{" "}
                                  {trainer.translateData[trainer.language] ? trainer.translateData[trainer.language].score : ''}
                                </label>
                              </div>
                              <Box>
                                {chartData?.trend_chart &&
                                  chartData.trend_chart.length > 0 ? (
                                  <ResponsiveChartContainer
                                    // width={700}
                                    height={300}
                                    series={[
                                      {
                                        data: chartData?.trend_chart.map(
                                          (linedata: any) => linedata.value
                                        ),
                                        type: "line",
                                        curve: "linear",
                                        color: "#FF9C80",
                                      },
                                    ]}
                                    xAxis={[
                                      {
                                        scaleType: "point",
                                        data: chartData?.trend_chart.map(
                                          (e: any) => e.key
                                        ),
                                      },
                                    ]}
                                    yAxis={[{ min: 0 }]}
                                  >
                                    <LinePlot />
                                    <MarkPlot />
                                    <ChartsTooltip />
                                    {/* <ChartsGridLines strokeDasharray="3 3" /> */}
                                    {/* <Tooltip /> */}
                                    {/* <ChartsReferenceLine
                                      y={chartData.trend_chart.average_score}
                                      lineStyle={{ stroke: "#76D45E" }}
                                    /> */}
                                    <ChartsXAxis
                                      label={trainer.translateData[trainer.language].time_period}
                                      labelFontSize={12}
                                    />
                                    <ChartsYAxis
                                      label={trainer.translateData[trainer.language].score}
                                      labelFontSize={12}
                                      labelStyle={{ marginTop: 10 }}
                                    />
                                  </ResponsiveChartContainer>
                                ) : null}
                              </Box>
                            </div>
                          </div>
                          ) : (
                            <>
                              <div className="page-loader section-loader">
                                <CircularProgress />
                              </div>
                            </>
                      )
                    }
                    </div>
                  </>
                ) : (
                  <>
                    <div className="page-loader section-loader">
                      <CircularProgress />
                    </div>
                  </>
                )}
                
              </div>
            </Box>
            <Box className="page-title mobile-view col-12">
              DASHBOARD
            </Box>
            <Box className="mobile-view px-0">
              <div className="d-flex flex-column w-100">
                <Box className="top-filter d-flex w-100">
                  {filterData?.map((filter, index: number) => (
                    <div key={index}>
                      <label className="form-label"> {filter.label} </label>
                      <FormControl>
                        <Select
                          value={filtervalue ? filtervalue[filter.key] : ""}
                          onChange={(event) => {
                            handleFilterChange(
                              event as React.ChangeEvent<{ value: unknown }>,
                              filter.key
                            );
                          }}
                        >
                          {filter.options.filter((option: any) => filter.key != 'language' ? (option.language ? option.language : 'en') == trainer.language : true).map((option: any, i: number) => (
                            <MenuItem key={i} value={option.value}>
                              {" "}
                              {option.title}{" "}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </div>
                  ))}
                </Box>
                <Box className="d-flex p-3">
                  {!(chartData?.donut_chart && chartData.donut_chart.filter((data: any) => data.score != null).length) 
                          ? <div className="me-3">
                              <h5 className="mb-0">{`*Please complete some interactions with ${filterData?.find((filter: any) => filter.key == 'customer_id').options.find((option: any) => option.value == filtervalue?.customer_id)?.title} for ${filtervalue?.personality_type} personality`}</h5>
                            </div> 
                          : <></>
                  }
                </Box>
              </div>
            </Box>
            <Box className="col-4 pt-3 mt-1 d-flex flex-column home-right">
              <h5 className="mb-3 pb-1 main-view"> {trainer.translateData[trainer.language] ? trainer.translateData[trainer.language].quick_actions : ''}</h5>
              <div className="row">
                <div className="col-6">
                  <Box className="action-box text-center" onClick={() => navigate("/dashboard/chats")}>
                    {/* <a href="#"> */}
                    <span className="d-block">
                      {" "}
                      <ConversionIcon />
                      <br /> {trainer.translateData[trainer.language] ? trainer.translateData[trainer.language].existing : ''} <br /> {trainer.translateData[trainer.language] ? trainer.translateData[trainer.language].convo : ''} 
                    </span>
                    {/* </a> */}
                  </Box>
                </div>
                <div className="col-6">
                  <Box className="action-box text-center" onClick={() => navigate("/dashboard/new-conversation")}>
                    {/* <a href="#"> */}
                    <span className="d-block">
                      <AddConversionIcon /> <br /> {trainer.translateData[trainer.language] ? trainer.translateData[trainer.language].start_new : ''}<br />{" "}
                      {trainer.translateData[trainer.language] ? trainer.translateData[trainer.language].convo : ''}
                    </span>
                    {/* </a> */}
                  </Box>
                </div>
              </div>
              <div className="row">
                <div className="col-12">
                  <Box className="text-left" >
                    <p className="small-text">
                    The content and feedback that you receive as part of this training is just one aspect of the overall Acadia training platform. We have attempted to anticipate a wide range of messaging scenarios and coaching feedback. In real world HCP interactions always follow all Compliance messaging guidance including having fair and balanced conversations by the inclusion of safety information, reporting AEs, submitting a MIRF when the inquiry is outside of approved messaging, etc. <br/><br/>
                    If you are ever unsure on the delivery of your message or want more in-depth feedback on your sales call approach please contact your manager, sales training, or Compliance.
                    </p>
                    <p className="small-text">
                    Due to a hallucination problem with generative AI, feedback from the model should not be fully relied on.
                    </p>
                  </Box>
                </div>
              </div>
              <div className="row flex-grow-1 main-view">
                {recommendationData ? (
                  <>
                    <h5 className="mb-3 pb-1">  {trainer.translateData[trainer.language] ? trainer.translateData[trainer.language].recommnedation : ''}</h5>
                    <ul className="list-unstyled recomm-list mb-0">
                      {recommendationData?.data.map(
                        (recommendation, index: number) => (
                          <li key={index}>
                            <div className="d-flex justify-content-between mb-3 align-items-center">
                              <h5 className="mb-0">{recommendation.title}</h5>{" "}
                              <NextArrow style={{ width: "14px" }} />
                            </div>
                            <p>{recommendation.description}</p>
                          </li>
                        )
                      )}
                    </ul>
                  </>
                ) : null}
              </div>

              <section className="mobile-view flex-column dashboard-middle">
                {/* tab view */}
                <Box>
                  {chartData?.donut_chart && chartData.donut_chart.map((data: any) => (
                    <FormControl
                    key={data.title}
                    className={`progress-list ${
                      data.title === radioValue ? "active-item" : ""
                    }`}
                    >
                      <RadioGroup
                        aria-labelledby="demo-controlled-radio-buttons-group"
                        name="controlled-radio-buttons-group"
                        value={radioValue}
                        onChange={(event) =>
                          handleChange(event, data.score)
                        }
                      >
                        <FormControlLabel
                          value={data.title}
                          control={<Radio />}
                          label={data.title}
                        />
                        <div className="custom-bar progress">
                          <div className="progress">
                            <div
                              className="progress-bar"
                              role="progressbar"
                              style={{
                                width: `${data.score ? data.score : 0}%`,
                                backgroundColor: data.color,
                              }}
                              aria-valuenow={data.score}
                              aria-valuemin={0}
                              aria-valuemax={100} // Update this to be a number
                            >
                              {/* style={{right:trainer.language == 'jp' ? -25:0}} */}
                              <span className={'mobile-score-span'} title={`${data.score != null ? data.score < 60 ? reportsScoreValues[0] : data.score < 80 ? reportsScoreValues[1] : reportsScoreValues[2]:  (trainer.translateData[trainer.language] ? trainer.translateData[trainer.language].na : '')}`} >
                                        {`${data.score != null ? data.score < 60 ? reportsScoreValues[0] : data.score < 80 ? reportsScoreValues[1] : reportsScoreValues[2]:  (trainer.translateData[trainer.language] ? trainer.translateData[trainer.language].na : '')}`}</span>
                            </div>
                          </div>
                        </div>
                      </RadioGroup>
                    </FormControl>
                  ))}

                </Box>
                <Box className="graph-select">
                  <div className="d-flex align-items-center justify-content-between">
                    <h5 className="mb-0"> {trainer.translateData[trainer.language] ? trainer.translateData[trainer.language].trend : ''}</h5>
                    <div className="d-flex flex-column">
                      <label className="legend me-4">
                        <span
                          className="score-legend"
                          style={{ backgroundColor: "#FF9C80" }}
                        ></span>{" "}
                        {trainer.translateData[trainer.language] ? trainer.translateData[trainer.language].score : ''}
                      </label>
                    </div>
                  </div>

                  <div className="graphBox">
                    {chartData?.trend_chart &&
                      chartData.trend_chart.length > 0 ? (
                      <ResponsiveChartContainer
                        width={700}
                        height={300}
                        series={[
                          {
                            data: chartData?.trend_chart.map(
                              (linedata: any) => linedata.value
                            ),
                            type: "line",
                            curve: "linear",
                            color: "#FF9C80",
                          },
                        ]}
                        xAxis={[
                          {
                            scaleType: "point",
                            data: chartData?.trend_chart.map(
                              (e: any) => e.key
                            ),
                          },
                        ]}
                        yAxis={[{ min: 0 }]}
                      >
                        <LinePlot />
                        <MarkPlot />
                        <ChartsTooltip />
                        {/* <ChartsGridLines strokeDasharray="3 3" /> */}
                        {/* <Tooltip /> */}
                        {/* <ChartsReferenceLine
                                  y={chartData.trend_chart.average_score}
                                  lineStyle={{ stroke: "#76D45E" }}
                                /> */}
                        <ChartsXAxis
                          label={trainer.translateData[trainer.language].time_period}
                          labelFontSize={12}
                        />
                        <ChartsYAxis
                          label={trainer.translateData[trainer.language].score}
                          labelFontSize={12}
                          labelStyle={{ marginTop: 10 }}
                        />
                      </ResponsiveChartContainer>
                    ) : null}
                  </div>
                </Box>
              </section>

            </Box>
          </Box>
        </>
      ) : null}
    </>
  );
}
