export const data = {
  status: "success",
  message: "success",
  data: [
    {
      title: "Tone",
      score: 100.0,
      is_compliant_recommendations: false,
      suggestion: [
        {
          question: "Was the ABM’s tone positive?",
          Score: 1,
          Comments:
            "The ABM maintained a positive and supportive tone throughout the conversation, focusing on addressing concerns and providing solutions.",
          "Areas of improvement": "N/A",
          "Sub question": [],
          threshold: 1,
        },
        {
          question:
            "Was the ABM sensitive towards a patient's needs and concerns if brought up by the HCP?",
          Score: 1,
          Comments:
            "The ABM showed sensitivity towards the patient's needs by acknowledging side effects and offering support programs.",
          "Areas of improvement": "N/A",
          "Sub question": [],
          threshold: 1,
        },
        {
          question: "Was the ABM gentle and patient when correcting the HCP?",
          Score: 1,
          Comments:
            "The ABM was respectful and patient, providing information without directly contradicting the HCP.",
          "Areas of improvement": "N/A",
          "Sub question": [],
          threshold: 1,
        },
        {
          question:
            "Did the ABM succeed in engaging the HCP with their responses?",
          Score: 1,
          Comments:
            "The ABM engaged the HCP effectively by addressing concerns, providing detailed information, and asking follow-up questions.",
          "Areas of improvement": "N/A",
          "Sub question": [],
          threshold: 1,
        },
        {
          question: "Was the agenda of the conversation set properly?",
          Score: 1,
          Comments:
            "The agenda was clear from the beginning, focusing on discussing Daybue, its side effects, and support for patients.",
          "Areas of improvement": "N/A",
          "Sub question": [],
          threshold: 1,
        },
      ],
    },
    {
      title: "On-label recommendations",
      score: 60.0,
      is_compliant_recommendations: true,
      suggestion: [
        {
          question:
            "Did the ABM compliantly stick to the DAYBUE label in discussion with the HCP?",
          Score: -1,
          Comments:
            "ABM did not touch upon label information during the conversation.",
          "Areas of improvement": "",
          threshold: 1,
          "Sub question": [
            {
              question:
                "Did the ABM mention discuss the use of DAYBUE for diseases other than Rett Syndrome?",
              Score: -1,
              Comments:
                "The ABM did not discuss the use of DAYBUE for diseases other than Rett Syndrome.",
              "Areas of improvement": "N/A",
            },
            {
              question:
                "Did the ABM correctly state DAYBUE can be administered orally or via gastrostomy (G) or gastrojejunal (GJ) tubes?",
              Score: -1,
              Comments:
                "The ABM did not mention the administration methods of DAYBUE.\nMention all approved administration routes for DAYBUE to provide comprehensive treatment options.",
              "Areas of improvement":
                "Mention all approved administration routes for DAYBUE to provide comprehensive treatment options.",
            },
            {
              question:
                "Did the ABM mention the LAVENDER trial was a 12-week, randomized, double-blind, placebo-controlled clinical trial of 187 female patients aged 5-20 years old, designed to evaluate the efficacy and safety of DAYBUE?",
              Score: -1,
              Comments:
                "The ABM mentioned the LAVENDER study but did not provide specific details about the trial's design or patient demographics.\nInclude detailed information about the LAVENDER trial to highlight the research supporting DAYBUE's efficacy and safety.",
              "Areas of improvement":
                "Include detailed information about the LAVENDER trial to highlight the research supporting DAYBUE's efficacy and safety.",
            },
            {
              question:
                "Did the ABM correctly state DAYBUE is indicated for the treatment of Rett Syndrome in adults and pediatric patients 2 years of age and older?",
              Score: -1,
              Comments:
                "The ABM did not specify the age range for DAYBUE's indication.\nClearly state the approved age range for DAYBUE to ensure accurate prescribing information.",
              "Areas of improvement":
                "Clearly state the approved age range for DAYBUE to ensure accurate prescribing information.",
            },
            {
              question:
                "Did the ABM correctly state DAYBUE is dosed based on weight?",
              Score: -1,
              Comments:
                "The ABM did not mention that DAYBUE is dosed based on weight.\nInclude dosing information to assist in proper medication administration.",
              "Areas of improvement":
                "Include dosing information to assist in proper medication administration.",
            },
          ],
          isStatic: true,
        },
        {
          question:
            "When citing statistics, did the ABM accurately inform the HCP of numbers from clinical trials, real-world usage, etc.?",
          Score: 0,
          Comments: "",
          "Areas of improvement": "",
          threshold: 1,
          "Sub question": [
            {
              question:
                "Did the ABM correctly state an almost 3 times greater mean RSBQ score reduction from baseline vs placebo, as early as 12 weeks?",
              Score: 1,
              Comments:
                "The ABM correctly mentioned the significant mean score reduction in RSVQ (assumed typo for RSBQ) in the LAVENDER study.",
              "Areas of improvement": "N/A",
            },
            {
              question:
                "Did the ABM correctly state that according to the CGI-I scale, 38% of patients saw improvement on DAYBUE vs 15% who were on placebo, as early as 12 weeks?",
              Score: 0,
              Comments:
                "The ABM mentioned almost 40% of patients improved, which is not the exact figure of 38% as specified but is close.\nEnsure to provide exact statistics for clarity and accuracy.",
              "Areas of improvement":
                "Ensure to provide exact statistics for clarity and accuracy.",
            },
            {
              question:
                "Did the ABM correctly state the rate of diarrhea to be 82% in DAYBUE patients versus 20% in patients on placebo?",
              Score: -1,
              Comments:
                "The ABM mentioned diarrhea as a common side effect but did not provide specific rates.\nProvide specific incidence rates of side effects to inform the doctor accurately.",
              "Areas of improvement":
                "Provide specific incidence rates of side effects to inform the doctor accurately.",
            },
            {
              question:
                "Did the ABM correctly state the rate of seizures to be 9% in DAYBUE patients versus 6% in patients on placebo?",
              Score: -1,
              Comments:
                "The ABM mentioned seizures as a side effect but did not provide specific rates.\nInclude specific data on the rate of seizures to enhance understanding of the drug's safety profile.",
              "Areas of improvement":
                "Include specific data on the rate of seizures to enhance understanding of the drug's safety profile.",
            },
            {
              question:
                "Did the ABM correctly state the rate of vomiting to be 29% in DAYBUE patients versus 12% in patients on placebo?",
              Score: -1,
              Comments:
                "The ABM mentioned vomiting as a side effect but did not provide specific rates.\nClarify the specific incidence rates of vomiting for a clear comparison between DAYBUE and placebo.",
              "Areas of improvement":
                "Clarify the specific incidence rates of vomiting for a clear comparison between DAYBUE and placebo.",
            },
            {
              question:
                "Did the ABM mention that caregivers used a 0- to 2-point scale to assess 45 symptoms of Rett Syndrome to determine the RSBQ total score, or that the maximum possible score is 90?",
              Score: -1,
              Comments:
                "The ABM did not mention the specific scale used for assessing symptoms of Rett Syndrome.\nDetail the assessment method used in trials to provide a clearer understanding of the drug's efficacy measures.",
              "Areas of improvement":
                "Detail the assessment method used in trials to provide a clearer understanding of the drug's efficacy measures.",
            },
            {
              question:
                "Did the ABM mention the LAVENDER trial was a 12-week, randomized, double-blind, placebo-controlled clinical trial of 187 female patients aged 5-20 years old, designed to evaluate the efficacy and safety of DAYBUE?",
              Score: -1,
              Comments:
                "The ABM mentioned the LAVENDER study but did not provide specific details about the trial's design or patient demographics.\nInclude detailed information about the LAVENDER trial to highlight the research supporting DAYBUE's efficacy and safety.",
              "Areas of improvement":
                "Include detailed information about the LAVENDER trial to highlight the research supporting DAYBUE's efficacy and safety.",
            },
            {
              question:
                "Did the ABM mention in DAYBUE patients, 96% of cases of diarrhea were mild to moderate?",
              Score: -1,
              Comments:
                "The ABM did not mention the severity of diarrhea cases in DAYBUE patients.\nDiscuss the severity of common side effects to help manage treatment expectations.",
              "Areas of improvement":
                "Discuss the severity of common side effects to help manage treatment expectations.",
            },
            {
              question:
                "Did the ABM mention at LAVENDER baseline, patients exhibited a range of clinical characteristics, disease severity and comorbidities?",
              Score: -1,
              Comments:
                "The ABM did not mention the baseline characteristics of patients in the LAVENDER trial.\nMention patient diversity in trials to demonstrate DAYBUE's applicability across different patient profiles.",
              "Areas of improvement":
                "Mention patient diversity in trials to demonstrate DAYBUE's applicability across different patient profiles.",
            },
            {
              question:
                "Did the ABM state 85% of patients in LAVENDER DAYBUE experienced diarrhea or 49% had persistent diarrhea or recurrent diarrhea after dose interruptions, reductions, or concomitant antidiarrheal therapy or 51% patients needed antidiarrheal medication for their diarrhea?",
              Score: -1,
              Comments:
                "The ABM did not provide specific statistics regarding diarrhea management in DAYBUE patients.\nDetail the management of side effects in clinical trials to offer strategies for handling common adverse events.",
              "Areas of improvement":
                "Detail the management of side effects in clinical trials to offer strategies for handling common adverse events.",
            },
            {
              question:
                "Did the ABM mention 12% of patients in LAVENDER experienced weight loss with DAYBUE compared to 4% of patients on placebo, and in the long term studies 2.2% discontinued DAYBUE because of weight loss?",
              Score: -1,
              Comments:
                "The ABM did not mention specific data on weight loss in DAYBUE patients.\nDiscuss the incidence and management of weight loss to provide a comprehensive overview of potential side effects.",
              "Areas of improvement":
                "Discuss the incidence and management of weight loss to provide a comprehensive overview of potential side effects.",
            },
            {
              question:
                "Did the ABM mention the most common side effects are diarrhea and vomiting?",
              Score: 1,
              Comments:
                "The ABM mentioned diarrhea and vomiting as common side effects, aligning with the question.",
              "Areas of improvement": "N/A",
            },
          ],
          isStatic: true,
        },
        {
          question: "Did the ABM make sure not to provide misinformation?",
          Score: 0,
          Comments: "",
          "Areas of improvement": "",
          threshold: 1,
          "Sub question": [
            {
              question:
                "Did the ABM mention discuss the use of DAYBUE for diseases other than Rett Syndrome?",
              Score: -1,
              Comments:
                "The ABM did not discuss the use of DAYBUE for diseases other than Rett Syndrome.",
              "Areas of improvement": "N/A",
            },
            {
              question:
                "Did the ABM discuss the LAVENDER™ trial before discussing LILAC-1™ or LILAC-2™ trial data?",
              Score: -1,
              Comments:
                "The ABM did not discuss the LAVENDER™ trial before discussing LILAC-1™ or LILAC-2™ trial data, as there was no mention of LILAC-1™ or LILAC-2™ trials.",
              "Areas of improvement": "N/A",
            },
            {
              question:
                "Did the ABM correctly state an almost 3 times greater mean RSBQ score reduction from baseline vs placebo, as early as 12 weeks?",
              Score: 1,
              Comments:
                "The ABM correctly mentioned the significant mean score reduction in RSVQ (assumed typo for RSBQ) in the LAVENDER study.",
              "Areas of improvement": "N/A",
            },
            {
              question:
                "Did the ABM correctly state that according to the CGI-I scale, 38% of patients saw improvement on DAYBUE vs 15% who were on placebo, as early as 12 weeks?",
              Score: 0,
              Comments:
                "The ABM mentioned almost 40% of patients improved, which is not the exact figure of 38% as specified but is close.\nEnsure to provide exact statistics for clarity and accuracy.",
              "Areas of improvement":
                "Ensure to provide exact statistics for clarity and accuracy.",
            },
            {
              question:
                "Did the ABM correctly state DAYBUE can be administered orally or via gastrostomy (G) or gastrojejunal (GJ) tubes?",
              Score: -1,
              Comments:
                "The ABM did not mention the administration methods of DAYBUE.\nMention all approved administration routes for DAYBUE to provide comprehensive treatment options.",
              "Areas of improvement":
                "Mention all approved administration routes for DAYBUE to provide comprehensive treatment options.",
            },
            {
              question:
                "Did the ABM correctly state the rate of diarrhea to be 82% in DAYBUE patients versus 20% in patients on placebo?",
              Score: -1,
              Comments:
                "The ABM mentioned diarrhea as a common side effect but did not provide specific rates.\nProvide specific incidence rates of side effects to inform the doctor accurately.",
              "Areas of improvement":
                "Provide specific incidence rates of side effects to inform the doctor accurately.",
            },
            {
              question:
                "Did the ABM correctly state the rate of seizures to be 9% in DAYBUE patients versus 6% in patients on placebo?",
              Score: -1,
              Comments:
                "The ABM mentioned seizures as a side effect but did not provide specific rates.\nInclude specific data on the rate of seizures to enhance understanding of the drug's safety profile.",
              "Areas of improvement":
                "Include specific data on the rate of seizures to enhance understanding of the drug's safety profile.",
            },
            {
              question:
                "Did the ABM correctly state the rate of vomiting to be 29% in DAYBUE patients versus 12% in patients on placebo?",
              Score: -1,
              Comments:
                "The ABM mentioned vomiting as a side effect but did not provide specific rates.\nClarify the specific incidence rates of vomiting for a clear comparison between DAYBUE and placebo.",
              "Areas of improvement":
                "Clarify the specific incidence rates of vomiting for a clear comparison between DAYBUE and placebo.",
            },
            {
              question:
                "Did the ABM mention that caregivers used a 0- to 2-point scale to assess 45 symptoms of Rett Syndrome to determine the RSBQ total score, or that the maximum possible score is 90?",
              Score: -1,
              Comments:
                "The ABM did not mention the specific scale used for assessing symptoms of Rett Syndrome.\nDetail the assessment method used in trials to provide a clearer understanding of the drug's efficacy measures.",
              "Areas of improvement":
                "Detail the assessment method used in trials to provide a clearer understanding of the drug's efficacy measures.",
            },
            {
              question:
                "Did the ABM mention the LAVENDER trial was a 12-week, randomized, double-blind, placebo-controlled clinical trial of 187 female patients aged 5-20 years old, designed to evaluate the efficacy and safety of DAYBUE?",
              Score: -1,
              Comments:
                "The ABM mentioned the LAVENDER study but did not provide specific details about the trial's design or patient demographics.\nInclude detailed information about the LAVENDER trial to highlight the research supporting DAYBUE's efficacy and safety.",
              "Areas of improvement":
                "Include detailed information about the LAVENDER trial to highlight the research supporting DAYBUE's efficacy and safety.",
            },
            {
              question:
                "Did the ABM mention in DAYBUE patients, 96% of cases of diarrhea were mild to moderate?",
              Score: -1,
              Comments:
                "The ABM did not mention the severity of diarrhea cases in DAYBUE patients.\nDiscuss the severity of common side effects to help manage treatment expectations.",
              "Areas of improvement":
                "Discuss the severity of common side effects to help manage treatment expectations.",
            },
            {
              question:
                "Did the ABM mention at LAVENDER baseline, patients exhibited a range of clinical characteristics, disease severity and comorbidities?",
              Score: -1,
              Comments:
                "The ABM did not mention the baseline characteristics of patients in the LAVENDER trial.\nMention patient diversity in trials to demonstrate DAYBUE's applicability across different patient profiles.",
              "Areas of improvement":
                "Mention patient diversity in trials to demonstrate DAYBUE's applicability across different patient profiles.",
            },
            {
              question:
                "Did the ABM correctly state DAYBUE is indicated for the treatment of Rett Syndrome in adults and pediatric patients 2 years of age and older?",
              Score: -1,
              Comments:
                "The ABM did not specify the age range for DAYBUE's indication.\nClearly state the approved age range for DAYBUE to ensure accurate prescribing information.",
              "Areas of improvement":
                "Clearly state the approved age range for DAYBUE to ensure accurate prescribing information.",
            },
            {
              question:
                "Did the ABM correctly state DAYBUE is dosed based on weight?",
              Score: -1,
              Comments:
                "The ABM did not mention that DAYBUE is dosed based on weight.\nInclude dosing information to assist in proper medication administration.",
              "Areas of improvement":
                "Include dosing information to assist in proper medication administration.",
            },
            {
              question:
                "Did the ABM state 85% of patients in LAVENDER DAYBUE experienced diarrhea or 49% had persistent diarrhea or recurrent diarrhea after dose interruptions, reductions, or concomitant antidiarrheal therapy or 51% patients needed antidiarrheal medication for their diarrhea?",
              Score: -1,
              Comments:
                "The ABM did not provide specific statistics regarding diarrhea management in DAYBUE patients.\nDetail the management of side effects in clinical trials to offer strategies for handling common adverse events.",
              "Areas of improvement":
                "Detail the management of side effects in clinical trials to offer strategies for handling common adverse events.",
            },
            {
              question:
                "Did the ABM mention 12% of patients in LAVENDER experienced weight loss with DAYBUE compared to 4% of patients on placebo, and in the long term studies 2.2% discontinued DAYBUE because of weight loss?",
              Score: -1,
              Comments:
                "The ABM did not mention specific data on weight loss in DAYBUE patients.\nDiscuss the incidence and management of weight loss to provide a comprehensive overview of potential side effects.",
              "Areas of improvement":
                "Discuss the incidence and management of weight loss to provide a comprehensive overview of potential side effects.",
            },
            {
              question:
                "Did the ABM mention the most common side effects are diarrhea and vomiting?",
              Score: 1,
              Comments:
                "The ABM mentioned diarrhea and vomiting as common side effects, aligning with the question.",
              "Areas of improvement": "N/A",
            },
          ],
          isStatic: true,
        },
        {
          question:
            "Did the ABM discuss DAYBUE side effect, tolerability, or safety information?",
          Score: 1,
          Comments:
            "The ABM discussed side effects and tolerability extensively, providing information on managing side effects and the safety profile of DAYBUE.",
          "Areas of improvement": "N/A",
          threshold: 1,
          "Sub question": [],
        },
        {
          question: "Did the ABM communicate a benefit of DAYBUE?",
          Score: 1,
          Comments:
            "The ABM communicated benefits of DAYBUE, including improvements in communication, alertness, and overall engagement in patients.",
          "Areas of improvement": "N/A",
          threshold: 1,
          "Sub question": [],
        },
        {
          question: "Did the ABM communicate a risk of DAYBUE?",
          Score: 1,
          Comments:
            "The ABM communicated risks associated with DAYBUE, specifically mentioning side effects such as diarrhea.",
          "Areas of improvement": "N/A",
          threshold: 1,
          "Sub question": [],
        },
      ],
    },
    {
      title: "Appropriate team reference/handoff",
      score: 100.0,
      is_compliant_recommendations: false,
      suggestion: [
        {
          question:
            "On the first mention, did the ABM spell out the full names for Family Access Manager or Medical Science Liaison?",
          Score: -1,
          Comments:
            "The ABM did not spell out the full names for Family Access Manager or Medical Science Liaison upon first mention.\nSpell out acronyms upon first use to ensure clarity.",
          "Areas of improvement":
            "Spell out acronyms upon first use to ensure clarity.",
          "Sub question": [],
          threshold: 1,
        },
        {
          question:
            "When HCPs cited concerns around copay/cost, or educating the family or patient support, did the ABM mention and offer to refer them to stakeholders like Family Access Manager or Acadia Connect?",
          Score: 1,
          Comments:
            "The ABM mentioned and offered to refer to Family Access Managers and Acadia Connect in response to concerns about patient support.",
          "Areas of improvement": "N/A",
          "Sub question": [],
          threshold: 1,
        },
        {
          question:
            "When HCPs asks questions about off-label usage, dosing recommendations, titration, or medical/scientific literature, did the ABM offer to connect them with a Medical Science Liaison (MSL)?",
          Score: -1,
          Comments:
            "The ABM did not offer to connect the HCP with a Medical Science Liaison (MSL) when discussing titration, which was not studied in clinical trials.\nOffer to connect HCPs with a Medical Science Liaison for detailed medical or scientific inquiries.",
          "Areas of improvement":
            "Offer to connect HCPs with a Medical Science Liaison for detailed medical or scientific inquiries.",
          "Sub question": [],
          threshold: 1,
        },
      ],
    },
    {
      title: "Meets conversation objectives",
      score: 37.5,
      is_compliant_recommendations: false,
      suggestion: [
        {
          question:
            "Did the ABM try to draw out specific concerns with DAYBUE to address or overcome?",
          Score: 1,
          Comments:
            "The ABM specifically addressed concerns about side effects and provided information on support and mitigation strategies.",
          "Areas of improvement": "N/A",
          "Sub question": [],
          threshold: 1,
        },
        {
          question:
            "Did the ABM succeed in building toward positive perception of DAYBUE?",
          Score: 1,
          Comments:
            "The ABM built a positive perception by highlighting the drug's benefits, support programs, and strategies to manage side effects.",
          "Areas of improvement": "N/A",
          "Sub question": [],
          threshold: 1,
        },
        {
          question:
            "Did the ABM ask pointed and specific questions to move the conversation toward near-term decision-making regarding DAYBUE?",
          Score: 1,
          Comments:
            "The ABM asked specific questions about the HCP's experiences and concerns, guiding the conversation towards considering DAYBUE for more patients.",
          "Areas of improvement": "N/A",
          "Sub question": [],
          threshold: 1,
        },
        {
          question:
            "Did the ABM offer to set up a follow-up event or visit within the next two weeks, or explicitly spell out next steps with the HCP?",
          Score: 0,
          Comments:
            "The ABM did not explicitly offer to set up a follow-up event or discuss next steps within a specific timeframe.\nThe ABM could improve by suggesting a specific follow-up meeting to discuss progress or further questions.",
          "Areas of improvement":
            "The ABM could improve by suggesting a specific follow-up meeting to discuss progress or further questions.",
          "Sub question": [],
          threshold: 1,
        },
        {
          question:
            "Did the ABM mention there are over 1,295 patients on DAYBUE therapy today?",
          Score: 0,
          Comments:
            "The ABM did not mention the number of patients currently on DAYBUE therapy.\nMentioning the number of patients on DAYBUE could help build trust and credibility.",
          "Areas of improvement":
            "Mentioning the number of patients on DAYBUE could help build trust and credibility.",
          "Sub question": [],
          threshold: 1,
        },
        {
          question:
            "Did the ABM mention there are over 580 unique prescribers of DAYBUE?",
          Score: 0,
          Comments:
            "The ABM did not mention the number of unique prescribers of DAYBUE.\nIncluding statistics on prescribers could demonstrate the drug's acceptance and usage in the medical community.",
          "Areas of improvement":
            "Including statistics on prescribers could demonstrate the drug's acceptance and usage in the medical community.",
          "Sub question": [],
          threshold: 1,
        },
        {
          question:
            "Did the ABM mention over 25% of the Rett patients on DAYBUE are over the age of 20 years old?",
          Score: 0,
          Comments:
            "The ABM did not provide information about the age distribution of Rett patients on DAYBUE.\nSharing age-related data could help the HCP understand the drug's applicability to a broader patient demographic.",
          "Areas of improvement":
            "Sharing age-related data could help the HCP understand the drug's applicability to a broader patient demographic.",
          "Sub question": [],
          threshold: 1,
        },
        {
          question:
            "Did the ABM mention around 99% of patients on DAYBUE pay less than $10 as final out-of-pocket cost for their prescription?",
          Score: 0,
          Comments:
            "The ABM did not discuss the out-of-pocket cost for patients on DAYBUE.\nDiscussing the affordability of DAYBUE could address potential concerns about cost.",
          "Areas of improvement":
            "Discussing the affordability of DAYBUE could address potential concerns about cost.",
          "Sub question": [],
          threshold: 1,
        },
      ],
    },
    {
      title: "Compliance flag",
      score: 0.0,
      is_compliant_recommendations: false,
      suggestion: [
        {
          question: "Does DAYBUE cure Rett Syndrome?",
          Score: -1,
          Comments: "This question was not addressed in the conversation.",
          "Areas of improvement": "N/A",
          "Sub question": [],
          threshold: 1,
        },
        {
          question: "What is DAYBUE’s MOA?",
          Score: -1,
          Comments: "The mechanism of action of DAYBUE was not discussed.",
          "Areas of improvement": "N/A",
          "Sub question": [],
          threshold: 1,
        },
        {
          question:
            "Isn’t titration safer than starting on the dose in the label right away?",
          Score: 0,
          Comments:
            "ABM discussed titration as an anecdotal strategy but did not refer to MSL or submit a MIRF.\nABM should refer to MSL for detailed medical inquiries.",
          "Areas of improvement":
            "ABM should refer to MSL for detailed medical inquiries.",
          "Sub question": [],
          threshold: 1,
        },
        {
          question:
            "Does DAYBUE work better in younger patients with developing brains?",
          Score: -1,
          Comments:
            "This specific question about patient age and efficacy was not covered.",
          "Areas of improvement": "N/A",
          "Sub question": [],
          threshold: 1,
        },
        {
          question: "Does DAYBUE allow nerves to form better connections?",
          Score: -1,
          Comments:
            "The conversation did not address DAYBUE's effects on nerve connections.",
          "Areas of improvement": "N/A",
          "Sub question": [],
          threshold: 1,
        },
        {
          question:
            "Does diarrhea go away after patients have been on DAYBUE for a while?",
          Score: 0,
          Comments:
            "ABM mentioned diarrhea as a side effect but did not specify if it diminishes over time, nor referred to MSL or MIRF.\nABM should refer to MSL for detailed side effect profiles.",
          "Areas of improvement":
            "ABM should refer to MSL for detailed side effect profiles.",
          "Sub question": [],
          threshold: 1,
        },
        {
          question:
            "When will I see a better formulation of DAYBUE that doesn’t cause diarrhea?",
          Score: -1,
          Comments:
            "No discussion on future formulations of DAYBUE was present.",
          "Areas of improvement": "N/A",
          "Sub question": [],
          threshold: 1,
        },
        {
          question: "Can I split the dose in 4 per day, versus two?",
          Score: -1,
          Comments: "Dosing frequency or splitting doses was not discussed.",
          "Areas of improvement": "N/A",
          "Sub question": [],
          threshold: 1,
        },
        {
          question:
            "Isn’t efficacy caused by Area Under Curve (AUC) and tolerability by Cmax (Maximum Concentration) usually?",
          Score: -1,
          Comments:
            "Pharmacokinetic details like AUC and Cmax were not mentioned.",
          "Areas of improvement": "N/A",
          "Sub question": [],
          threshold: 1,
        },
        {
          question: "What new indications is Acadia working on now for DAYBUE?",
          Score: -1,
          Comments: "Future indications for DAYBUE were not discussed.",
          "Areas of improvement": "N/A",
          "Sub question": [],
          threshold: 1,
        },
        {
          question:
            "What are DAYBUE’s long-term effects on Rett Syndrome symptoms and overall development? Are there benefits beyond twelve weeks?",
          Score: 0,
          Comments:
            "ABM mentioned there are no long-term side effects but did not refer to MSL or MIRF for detailed information.\nABM should refer to MSL for detailed information on long-term effects.",
          "Areas of improvement":
            "ABM should refer to MSL for detailed information on long-term effects.",
          "Sub question": [],
          threshold: 1,
        },
        {
          question:
            "Could DAYBUE work synergistically with other medications or therapies for Rett Syndrome? Have any studies explored this?",
          Score: 0,
          Comments:
            "ABM advised against combining DAYBUE with other medications or therapies but did not refer to MSL or MIRF.\nABM should refer to MSL for information on drug interactions and combination therapy.",
          "Areas of improvement":
            "ABM should refer to MSL for information on drug interactions and combination therapy.",
          "Sub question": [],
          threshold: 1,
        },
        {
          question:
            "Beyond symptom scores, how does DAYBUE affect the everyday quality of life for Rett Syndrome patients and their families?",
          Score: 0,
          Comments:
            "ABM discussed improvements in patient engagement and communication but did not refer to MSL or MIRF.\nABM should refer to MSL for detailed quality of life data.",
          "Areas of improvement":
            "ABM should refer to MSL for detailed quality of life data.",
          "Sub question": [],
          threshold: 1,
        },
        {
          question:
            "Why do some patients respond better to DAYBUE than others? Are there genetic or other factors that could predict treatment response?",
          Score: -1,
          Comments:
            "Variability in patient response was mentioned but not the reasons behind it.",
          "Areas of improvement": "N/A",
          "Sub question": [],
          threshold: 1,
        },
        {
          question:
            "Does DAYBUE interact with other medications commonly used by Rett Syndrome patients?",
          Score: 0,
          Comments:
            "ABM advised against combining DAYBUE with other medications but did not refer to MSL or MIRF.\nABM should refer to MSL for detailed information on drug interactions.",
          "Areas of improvement":
            "ABM should refer to MSL for detailed information on drug interactions.",
          "Sub question": [],
          threshold: 1,
        },
        {
          question:
            "Are researchers exploring other ways to administer DAYBUE to potentially improve tolerability?",
          Score: -1,
          Comments:
            "Alternative administration methods for DAYBUE were not discussed.",
          "Areas of improvement": "N/A",
          "Sub question": [],
          threshold: 1,
        },
        {
          question:
            "What resources are available for families of patients on DAYBUE to manage treatment expectations and potential side effects?",
          Score: 0,
          Comments:
            "ABM provided information on support resources but did not refer to MSL or MIRF.\nABM should refer to MSL for comprehensive support information.",
          "Areas of improvement":
            "ABM should refer to MSL for comprehensive support information.",
          "Sub question": [],
          threshold: 1,
        },
        {
          question:
            "Does DAYBUE have any effects on specific cognitive domains (e.g., attention, language, social skills) in Rett Syndrome patients?",
          Score: -1,
          Comments: "Specific cognitive domain effects were not discussed.",
          "Areas of improvement": "N/A",
          "Sub question": [],
          threshold: 1,
        },
        {
          question:
            "Could any changes in blood or other biological markers help track DAYBUE’s effectiveness or predict individual responses?",
          Score: -1,
          Comments:
            "The conversation did not cover biomarkers for tracking DAYBUE's effectiveness.",
          "Areas of improvement": "N/A",
          "Sub question": [],
          threshold: 1,
        },
        {
          question:
            "Can DAYBUE improve the results I see with OT/PT and Speech therapy?",
          Score: -1,
          Comments:
            "Improvements in therapy outcomes with DAYBUE use were not discussed.",
          "Areas of improvement": "N/A",
          "Sub question": [],
          threshold: 1,
        },
        {
          question: "Does DAYBUE reduce seizures?",
          Score: -1,
          Comments: "Seizure reduction with DAYBUE was not mentioned.",
          "Areas of improvement": "N/A",
          "Sub question": [],
          threshold: 1,
        },
      ],
    },
  ],
};
