import React, { useState } from "react";
import './conversation.scss';
import ChatWindow from "../../components/chat-window/chat-window";
import { RecentChat } from "../../components/recent-chat/recent-chat";
import Grid from "@mui/material/Grid/Grid";
import { Services } from "../../../shared/services";
import { useAuth } from "../../../../utils/auth/auth.guard";
import { RECENT_CHAT_RESPONSE } from "../../../../assets/data/json/trainer-responses";
import { useParams } from "react-router-dom";
import { useTrainer } from "../../services/trainer.service";

export default function Conversation() {

    const isSmallDevice = () => window.innerWidth <= 700;
    const [checked, setChecked] = React.useState(false);
    const AppService = React.useContext(Services.AppService);
    const auth = useAuth()!;
    const params = useParams();
    const trainer = useTrainer();
    const [isSmallResolution, $isSmallResolution] = useState(isSmallDevice())

    const getAllConversations = async () => {
        AppService.API_HANDLER({
            auth,
            requestMethod: "get",
            url: "https://api.vrt.zsservices.com/conversations",
            useGlobalLoader: true,
            showErrorMessage: true,
            showSuccessMessage: false,
            fetchFromApi: true,
            LOCAL_RESPONSE: RECENT_CHAT_RESPONSE,
            successCb: (res: any) => {
                if (params && params["conversationId"]) {
                    trainer.setSelectedChat(res.data.find((d: any) => d.conversation_id === params["conversationId"]))
                }
                trainer.setChats(res.data);
            }
        });
    }

    React.useEffect(() => {
        trainer.setSelectedChat(null)
        getAllConversations();
        function handleResize() {
            $isSmallResolution(isSmallDevice());
        }
        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
            trainer.setSelectedChat(null)
        }
    }, [])

    return ( auth.authed &&
        <div className="h-100 d-flex chat-screen">
            {
                ((isSmallResolution && !trainer.selectedChat) || !isSmallResolution)
                    ? (
                        <Grid item xs={2.25}>
                            <RecentChat checked={checked}  setChecked={setChecked}/>
                        </Grid>
                    )
                    : null
            }
            {
                ((isSmallResolution && trainer.selectedChat) || !isSmallResolution)
                    ? (
                        <Grid item xs={9.75} className="flex-grow-1">
                            <ChatWindow checked={checked}  setChecked={setChecked} />
                        </Grid>
                    )
                    : null
            }

        </div>
    )
}