import React from "react";
import './chat-report.scss';
import { Box } from "@mui/material";
import { Services } from "../../../shared/services";
import { RadialBarChart } from "../radial-bar-chart/radial-bar-chart";
import { useAuth } from "../../../../utils/auth/auth.guard";
import { useTrainer } from "../../services/trainer.service";
import { REPORTS_RESPONSE } from "assets/data/json";
import { reportsScoreValues } from "utils/enums/enum";

export function ChatReport() {
    const AppService = React.useContext(Services.AppService);
    const auth = useAuth()
    const trainer = useTrainer();
    const [data, setData] = React.useState<any>(null);
    const [totalScore, setTotalScore] = React.useState(0);
    const [suggetionClass, setSuggetionClass] = React.useState('')

    const colors = [
        "#FFED48",
        "#43ACD9",
        "#76D45E",
        "#8B45D0",
        "#3865DA",
    ]
    const loadReport = async () => {
        auth.setLoading(true);
        setData(null);
        AppService.API_HANDLER({
            auth,
            requestMethod: "post",
            url: "https://api.vrt.zsservices.com/report",
            payload: {
                "id": trainer.selectedChat.conversation_id, // "XMKZyD3GSFoPZATSLz4qC9Sm6PHkWe18Az37WDvWmMY9JvNMwd"
                // env: 'dev',,
                "customer_id":trainer.selectedChat.customer_id,
                language: trainer.language
            },
            useGlobalLoader: false,
            showErrorMessage: true,
            showSuccessMessage: false,
            fetchFromApi: true,
            LOCAL_RESPONSE: REPORTS_RESPONSE,
            successCb: (res: any) => {
                if (trainer.language == 'jp') {
                    res.data.forEach((v: any, i: number) => {
                        v.color = colors[i%colors.length];
    
                        // Do calculations for on label recommendations topic
                        if (v.is_compliant_recommendations) {
                            // console.log({v})
                          
                            v.score = 0;
                            v.suggestion.forEach((que: any) => {
                                // console.log(que)
                                if (que['Sub question'].length) {
                                    const subQuestionScore = que['Sub question'].reduce((old: any, curr: any) => old += curr.Score, 0)/que['Sub question'].length
                                    const score = +subQuestionScore >= que.threshold ? 1 : 0;
                                    que.Score = score;
                                    v.score += score;
                                } else if (que.isStatic){
                                    que.Score = -1;
                                }
                            })
                          
                        }
                        v.normalScore = v.score;
                        const score = +v.score / +v.weightage * 100;
                        [v.marks, v.score] = [v.score, score];
                    })
                    setTimeout(() => {
                        setData(res.data);
                        auth.setLoading(false);
                    }, 500);
                    setTimeout(() => {
                        setTotalScore(Math.round(res.data.reduce((old:any, curr:any) => old += +curr.marks, 0) / res.data.reduce((old:any, curr:any) => old += curr.weightage, 0)*100))
                    }, 750);
                } else {
                    const tempData = res.data.filter((v: any) => {
                        if(v.title == 'Compliance flag' && !v.suggestion.filter((sugg: any) => sugg.Score != -1).length) {
                            return false;
                        }
                        return true;
                    })
                    let totalYes = 0;
                    let total = 0
                    tempData.forEach((v: any, i: number) => {
                        v.color = colors[i%colors.length];
                        if (v.title == 'Compliance flag') {
                            v.suggestion = v.suggestion.filter((sugg: any) => sugg.Score != -1)
                        }
                        v.suggestion.forEach((sugg: any) => {
                            totalYes += sugg.Score == 1 ? 1 : 0;
                            total += sugg.Score == 1 || sugg.Score == 0 ? 1 : 0;
                        })
                    })
                    setTimeout(() => {
                        setData(tempData);
                        auth.setLoading(false);
                    }, 500);
                    setTimeout(() => {
                        setTotalScore(Math.round((totalYes/total)*100))
                    }, 750);
                }
            },
            failedCb: () => {
                setData([])
                auth.setLoading(false)
            }
        });
    }
    React.useEffect(() => {
        if (!trainer.selectedChat) return
        trainer.selectedChat.status === "completed"
            ? loadReport()
            : console.log("Please select completed chat")
    }, [trainer.selectedChat])

    return (
        <section className="p-3 d-flex flex-column flex-grow-1">
        {
            data
            ? (
                <>
                <Box className="BarChart d-flex align-items-center mb-4">
                    <div className="d-flex flex-column align-items-center">
                        <span className="main-view">{Math.round(+totalScore) < 60 ? reportsScoreValues[0] : Math.round(+totalScore) < 80 ? reportsScoreValues[1] : reportsScoreValues[2] }</span>
                        <div className="score">
                            {/* <small className="mobile-view ms-3 mt-1">{Math.round(+totalScore) < 60 ? reportsScoreValues[0] : Math.round(+totalScore) < 80 ? reportsScoreValues[1] : reportsScoreValues[2] }</small> */}
                            <div className= {`score-status ${totalScore < 50 ? "low-score" : ""}`}>
                                <strong>{Math.round(+totalScore)}<small>%</small></strong>
                                <div className="total" style={{height: `${totalScore}%`}}>
                                    <strong>{Math.round(+totalScore)}<small>%</small></strong>
                                </div>
                            </div>
                            <span>{trainer.translateData[trainer.language].totalScore}</span>
                        </div>
                    </div>
                    {data && <RadialBarChart data={data 
                    } size={300}></RadialBarChart>}
                </Box>
                <section className="report-list">
                    {/* <div className="report-link">
                        <span  onClick= {() => {setSuggetionClass(v => v ? '' : 'expanded')}}>View Description</span>   
                    </div> */}
                    {
                        data 
                        ? (
                            <Box>
                                {
                                    data.map((report: any, i:number) => (
                                        <>
                                            {trainer.language == 'jp' ?
                                                <div key={i} className="col">
                                                <div className="report-box">
                                                    <div className="report-top d-flex justify-content-between align-items-center">
                                                        <h6 className="mb-0">
                                                            <span style={{backgroundColor: report.color}}></span>
                                                            {report.title}
                                                        </h6>
                                                        <span>{parseInt(`${report.normalScore}`)} <small> {trainer.translateData[trainer.language].of} {report.weightage}</small></span>
                                                    </div>
                                                    {/* className={suggetionClass} */}
                                                    <ul>
                                                        {report.suggestion.length ? 
                                                            report.suggestion.map((suggestion: any, index: number) => (
                                                            
                                                            <li>
                                                                <p>{suggestion.question} <span style={{opacity: 1}} className={`text-decoration-underline fw-bold ${!report.is_compliant_recommendations && suggestion.Score === 0  && report.title === 'Compliance flag' && 'compliance-flag'}`}>{suggestion.Score === 1 ? trainer.translateData[trainer.language].yes :suggestion.Score === 0 ? trainer.translateData[trainer.language].no:trainer.translateData[trainer.language].na}</span></p>
                                                                {suggestion.isStatic && <p>{suggestion.Score === 1 ? 'ABM met all criteria during conversation.' : suggestion.Score === 0 ? 'ABM did not meet all criteria during conversation.' : 'ABM did not provide enough information during conversation' }</p> }
                                                                {!report.is_compliant_recommendations ||!suggestion['Sub question']?.length ? 
                                                                <>
                                                                    <p className={`${suggestion.Score === 0  && report.title === 'Compliance flag' && 'compliance-flag'}`}>{suggestion.Comments}</p></>
                                                                    :                                                               
                                                                        <ul>
                                                                            {suggestion['Sub question'].map((subQuestion: any, i: number) => (
                                                                                <li key={i}>
                                                                                    <p>{subQuestion.question} <strong> <u>{subQuestion.Score === 1 ? trainer.translateData[trainer.language].yes :subQuestion.Score === 0 ? trainer.translateData[trainer.language].no:trainer.translateData[trainer.language].na}</u></strong></p>
                                                                                    <p>{subQuestion.Comments}</p>
                                                                                </li>
                                                                            ))}
                                                                            
                                                                        </ul>
                                                                      
                                                                      
                                                                }
                                                            </li>
                                                            ))
                                                            : 'N/A'}
                                                    </ul>
                                                </div>
                                            </div>
                                            : 
                                            <div key={i} className="col">
                                                    <div className="report-box">
                                                        <div className="report-top d-flex justify-content-between align-items-center">
                                                            <h6 className="mb-0">
                                                                <span style={{backgroundColor: report.color}}></span>
                                                                {report.title}
                                                            </h6>
                                                            
                                                            <span>{`${+report.score < 60 ? reportsScoreValues[0] : +report.score < 80 ? reportsScoreValues[1] : reportsScoreValues[2] }`}</span>
                                                        </div>
                                                        {/* className={suggetionClass} */}
                                                        <ul>
                                                            {report.suggestion.length ? 
                                                                report.suggestion.map((suggestion: any, index: number) => (
                                                                
                                                                <li>
                                                                    <p>{suggestion.question} <span style={{opacity: 1}} className={`text-decoration-underline fw-bold ${!report.is_compliant_recommendations && suggestion.Score === 0  && report.title === 'Compliance flag' && 'compliance-flag'}`}>{suggestion.Score === 1 ? trainer.translateData[trainer.language].yes :suggestion.Score === 0 ? trainer.translateData[trainer.language].no:trainer.translateData[trainer.language].na}</span></p>
                                                                    {/* {suggestion.isStatic && <p>{suggestion.Comments }</p> } */}
                                                                    <p className={`${suggestion.Score === 0  && report.title === 'Compliance flag' && 'compliance-flag'}`}>{suggestion.Comments}</p>
                                                                    {report.is_compliant_recommendations &&                                                               
                                                                            <ul>
                                                                                {suggestion['Sub question'].filter((que: any) => que.Score != -1).map((subQuestion: any, i: number) => (
                                                                                    <li key={i}>
                                                                                        <p>{subQuestion.question} <strong> <u>{subQuestion.Score === 1 ? trainer.translateData[trainer.language].yes :subQuestion.Score === 0 ? trainer.translateData[trainer.language].no:trainer.translateData[trainer.language].na}</u></strong></p>
                                                                                        <p>{subQuestion.Comments}</p>
                                                                                    </li>
                                                                                ))}
                                                                                
                                                                            </ul>
                                                                    }
                                                                </li>
                                                                
                                                                ))
                                                                : 'N/A'}
                                                        </ul>
                                                    </div>
                                                </div>
                                             }
                                            {/* {report.suggestion.filter((sugg: any) => report.title == 'Compliance flag' ? sugg.Score != -1 : true).length != 0 && }  */}
                                        </>
                                    ))
                                }
                        </Box>
                        )
                        :null
                    }
                </section>
                </>
            )
            : null
        }
        </section>
    )
}