import { createTheme } from '@mui/material';

export const theme = createTheme({
    palette: {
        mode: 'light',
        divider: '#3E3E3E',
        primary: {
            main: '#C94890',
            contrastText: '#FFFFFF'
        },
        secondary: {
            main: '#E33E7F'
        }
    },
    typography: {
        fontSize: 12,
    },
})