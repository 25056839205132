import React from 'react';
import CommonService from './common.service';
import HTTP from './htttp-wrapper.service';

export const Services = {
    AppService: React.createContext(
        new CommonService(
            new HTTP()
        )
    )
}