import React from "react";
import "./new-conversation.scss";
import {
  Stepper,
  Step,
  StepLabel,
  Paper,
  StepIconProps,
  styled,
  StepConnector,
  stepConnectorClasses,
  Box,
  Button,
  Typography,
  Divider,
} from "@mui/material";
import {
  BackIcon,
  CheckIcon,
  CustomerIcon,
  DifficultyIcon,
  TopicIcon,
} from "../../../../assets/icons/Icons";
import { MuiButton } from "../../../shared/components/mui/button";
import { Services } from "../../../shared/services";
import { useAuth } from "../../../../utils/auth/auth.guard";
import { CHAT_CONFIG_RESPONSE } from "../../../../assets/data/json";
import {
  ChatConfig,
  ChatConfigResponse,
} from "../../../../utils/interfaces/interface";
import { Icons } from "../../../../utils/enums/enum";
import { useNavigate } from "react-router-dom";

export default function NewConversation() {
  const AppService = React.useContext(Services.AppService);
  const auth = useAuth()!;
  const navigate = useNavigate();

  const StepIcons: any = {
    [Icons.topic]: <TopicIcon />,
    
    [Icons.customer]: <CustomerIcon />,
    [Icons.difficulty]: <DifficultyIcon />,
    [Icons.personality_type]: <DifficultyIcon />,
  };
  const connectorStyle = {
    "--s": `8px`,
    height: `4px`,
    background: `
                radial-gradient(circle closest-side,#DD6848 98%,#0000) 0 0/var(--s) 100%, linear-gradient(90deg,#DD6848 50%, #0000 0) calc(var(--s)/2) 0/calc(2*var(--s)) 100%`,
  };
  const CustomStepConnector = styled(StepConnector)(({ theme }) => ({
    [`&.${stepConnectorClasses.alternativeLabel}`]: {
      top: 20,
    },
    [`&.${stepConnectorClasses.active}`]: {
      [`& .${stepConnectorClasses.line}`]: connectorStyle,
    },
    [`&.${stepConnectorClasses.completed}`]: {
      [`& .${stepConnectorClasses.line}`]: connectorStyle,
    },
    [`& .${stepConnectorClasses.line}`]: {
      height: 4,
      border: 0,
      backgroundColor:
        theme.palette.mode === "dark" ? theme.palette.grey[800] : "#3E3E3E",
      borderRadius: 1,
    },
  }));

  const [values, setValues] = React.useState<any>({
    "domain": "Healthcare",
    "topic": "Introduction and Product benefit",
    "objective": "Deepen Relationship",
  });
  const [activeStep, setActiveStep] = React.useState(0);
  const [steps, setSteps] = React.useState<ChatConfig[]>([]);

  function CustomStepIcon(props: { stepIcon: any } & StepIconProps) {
    return (
      <div
        className={
          "step-icon " + (props.active || props.completed ? "active" : "")
        }
      >
        {StepIcons[props.stepIcon]}
      </div>
    );
  }

  const nextHandler = () => {
    if (activeStep < steps.length - 1) {
      setActiveStep(activeStep + 1);
      // setValues((v: any) => ({...v, customer_id: null}))
    } else {
      startNewConversation();
    }
  };

  const disableNext = () => {
    const keys = steps[activeStep].data.map((d) => d.key);
    for (let i = 0; i < keys.length; i++) {
      if (!values[keys[i]]) return true;
    }
    return false;
  };

  const getConfigData = async () => {
    // console.log("hello")
    AppService.API_HANDLER({
      auth,
      requestMethod: "get",
      url: "https://api.vrt.zsservices.com/chat-config",
      // payload: {
      //   env: 'dev'
      // },
      useGlobalLoader: true,
      showErrorMessage: true,
      showSuccessMessage: false,
      fetchFromApi: true,
      LOCAL_RESPONSE: CHAT_CONFIG_RESPONSE,
      successCb: (res: ChatConfigResponse) => {
        // console.log(res);
        
        const data=res.data
        setSteps(data);
      },
    });
  };

  const startNewConversation = () => {
    auth.setLoading(true)
    AppService.API_HANDLER({
      auth,
      requestMethod: "post",
      url: "https://api.vrt.zsservices.com/new-chat",
      payload: {
        ...values,
      },
      useGlobalLoader: true,
      showErrorMessage: true,
      showSuccessMessage: false,
      fetchFromApi: true,
      LOCAL_RESPONSE: CHAT_CONFIG_RESPONSE,
      successCb: (res: any) => {
        navigate("/dashboard/" + res.data.id);
        auth.setLoading(false)
      },
      failedCb: () => {
        auth.setLoading(false)
    }
    });
  };

  React.useEffect(() => {
    getConfigData();
  }, []);

  return steps && steps.length && auth.authed ? (
    <section className="new-wrapper">
      <div className="step-container">
        <Box className="page-title"> <MuiButton
              variant="text"
              startIcon={<BackIcon />}
              onClick={() => navigate(-1)}
              className="back-link mobile-view"
            >
              
            </MuiButton> New Conversation</Box>
        <Box className="d-flex mt-3 justify-content-between">
          <Box>
            <MuiButton
              variant="text"
              startIcon={<BackIcon />}
              onClick={() => navigate(-1)}
              className="back-link main-view"
            > Back
            </MuiButton>
          </Box>
          <Stepper
            style={{ minWidth: "60vw" }}
            alternativeLabel
            activeStep={activeStep}
            connector={<CustomStepConnector />}
          >
            {steps.map((step) => (
              <Step key={step.label}>
                <StepLabel
                  StepIconComponent={(props) =>
                    CustomStepIcon({ ...props, stepIcon: step.icon })
                  }
                >
                  {step.label}
                </StepLabel>
              </Step>
            ))}
          </Stepper>
          <Box></Box>
        </Box>
      </div>
      <Divider light />
      {
        <React.Fragment>
         <Box component='div'
                className="steps-middle main-view"
                sx={{
                  "& > :not(style)": {
                    p: 2,
                  },
                }}
              >
                {steps[activeStep].data.map((stepData: any) => (
                  <>
                    {steps[activeStep].key !== 'customer_id' ?
                      <div key={stepData.key} className="option-list">
                        <h6>{stepData.title}</h6>     
                        <Box component='div'
                          sx={{
                            display: "flex",
                            "& > :not(style)": {
                              m: 1,
                              p: 1,
                              width: 200,
                              height: 70,
                            },
                          }}
                        >
                          {stepData.options && stepData.options.filter((option: any) => {
                            return (option['domain']) ? option['domain'] == values['domain'] : true
                          }).map((option: any) => (
                            <Paper
                              className={
                                "position-relative option-card " +
                                (values[stepData.key] === option.value ? "active" : "")
                              }
                              key={option.value}
                              onClick={() =>  
                                setValues((v: any) => ({
                                  ...v,
                                  [stepData.key]: option.value,
                                }))
                              }
                            >
                              {values[stepData.key] === option.value ? (
                                <CheckIcon className="check" />
                              ) : null}
                                <Typography className="mx-4">{option.title}</Typography>
                            </Paper>
                          ))}
                        </Box>
                      </div>
                      : 
                      <div key={stepData.key} className={`option-list customer-list`}>
                        <h6>{stepData.title}</h6>
                        <div className="d-flex justify-content-between" style={{overflow: 'hidden', height: 400}}>
                          <Box component='div'
                            sx={{
                              display: "flex",
                              flexDirection: 'column',
                              overflowY: 'auto',
                              overflowX: 'hidden',
                              width: '20%',
                              "& > :not(style)": {
                                m: 1,
                                p: 1,
                                width: '100%',
                                height: 70,
                              },
                            }}
                          >
                            {stepData.options
                            .filter((option: any) => option.domain === values["domain"]).map((option: any) => (
                              <Paper
                                className={
                                  `position-relative option-card mb-3 ` +
                                  (values[stepData.key] === option.value ? "active" : "")
                                }
                                key={option.value}
                                onClick={() =>  
                                  setValues((v: any) => ({
                                    ...v,
                                    [stepData.key]: option.value,
                                  }))
                                }
                              >
                                {values[stepData.key] === option.value ? (
                                  <CheckIcon className="check" />
                                ) : null}
                                  <Box component='div'>
                                    <div className="profile d-flex">
                                      <div className="profile-img">
                                        <img className="img-fluid img-circle" src={require(`assets/images/profile/${option.image_id?option.image_id:'M4'}.png`)} alt="" /> 
                                      </div>
                                      <div className="text-start">
                                          <h6 className="mb-0">{option.name}</h6>
                                          <small>{option.role}</small>
                                      </div>
                                    </div>
                                  </Box>
                              </Paper>
                            ))}
                          </Box>
                          <Box component='div' className='profile-content-main' sx={{  
                              width:'80%',
                              marginLeft: '30px'
                            }}>
                            {
                              stepData.options.filter((option: any) => option.value == values[stepData.key]).length ?
                              stepData.options.filter((option: any) => option.value == values[stepData.key]).map((option: any) => (
                                <>
                                {/* style={{width: '115px', height: '115px', flex: '0 0 115px', marginBottom: '20px'}} */}
                                  <div className="profile-img">
                                    <img className="img-fluid img-circle" src={require(`../../../../assets/images/profile/${option.image_id?option.image_id:'M4'}.png`)} alt="" />
                                  </div>
                                  <div className="content-text">
                                    <h6 className="mb-1">{option.name}</h6>
                                    <small className="mb-3 d-block">
                                      {option.role}
                                    </small>
                                    <p>
                                      {option.description}
                                    </p>
                                  </div>
                                </>
                              ))
                              :
                              <div className="w-100 h-100 d-flex justify-content-center align-items-center">
                                No Customer Selected
                              </div>
                            }
                          </Box>
                        </div>
                      </div>
                    }
                  </>
                ))}
              </Box>
          <Box
            className="steps-middle mobile-view"
            sx={{
              "& > :not(style)": {
                p: 2,
              },
            }}
          >
            {steps[activeStep].data.map((stepData) => (
              <div key={stepData.key} className={`option-list ${steps[activeStep].key === 'customer_id' ? 'customer-list': ''}`}>
                <h6>{stepData.title}</h6>
                <Box
                  sx={{
                    display: "flex",
                    "& > :not(style)": {
                      m: 1,
                      p: 1,
                      width: 200,
                      height: 70,
                    },
                  }}
                >
                  {/* <>{console.log({stepData})}</> */}
                  {stepData.options
                  // .filter((option: any) => {
                    // return activeStep === 0
                    // ? option.domain === 'Healthcare'
                    // : true
                  // })
                  .map((option: any) => (
                    <Paper
                      className={
                        "position-relative option-card " +
                        (values[stepData.key] === option.value ? "active" : "")
                      }
                      key={option.value}
                      onClick={() =>  
                        setValues((v: any) => ({
                          ...v,
                          [stepData.key]: option.value,
                        }))
                      }
                    >
                      {values[stepData.key] === option.value ? (
                        <CheckIcon className="check" />
                      ) : null}
                      {stepData.key === "customer_id" ? (
                        <Box>
                          <div className="profile d-flex">
                            <div className="profile-img">
                              <img className="img-fluid img-circle" src={require(`../../../../assets/images/profile/${option.image_id?option.image_id:'M4'}.png`)} alt="" /> 
                            </div>
                            <div className="text-start">
                                <h6 className="mb-0">{option.name}</h6>
                                <small>{option.role}</small>
                            </div>
                          
                          </div>
                          <Box className="profile-content">
                            <div className="profile-img">
                              <img className="img-fluid img-circle" src={require(`../../../../assets/images/profile/${option.image_id?option.image_id:'M4'}.png`)} alt="" />
                            </div>
                            <div className="content-text">
                              <h6 className="mb-1">{option.name}</h6>
                              <small className="mb-3 d-block">
                                {option.role}
                              </small>
                              <p>
                                {option.description}
                              </p>
                            </div>
                          </Box>
                        </Box>
                      ) : (
                        <Typography className="mx-4">{option.title}</Typography>
                      )}
                    </Paper>
                  ))}
                </Box>
              </div>
            ))}
          </Box>
          
          <Box className="step-button text-end">
            {activeStep > 0 ? (
              <MuiButton
                color="primary"
                variant="outlined"
                sx={{ mr: 1 }}
                onClick={() => setActiveStep(activeStep - 1)}
              >
                Previous
              </MuiButton>
            ) : null}

            <MuiButton
              className="ms-auto"
              onClick={nextHandler}
              color="primary"
              variant={
                activeStep === steps.length - 1 ? "contained" : "outlined"
              }
              disabled={disableNext()}
            >
              {activeStep === steps.length - 1 ? "Begin Simulation" : "Next"}
            </MuiButton>
          </Box>
        </React.Fragment>
      }
    </section>
  ) : null;
}
