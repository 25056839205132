import React from "react";
import "./recent-chat.scss";

import { MuiSwitch } from "../../../shared/components/mui/switch";
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from "../../../shared/components/mui/accordion";
import {
  Box,
  Avatar,
  Chip,
  Typography,
  Stack,
  FormControl,
  Select,
  MenuItem,
  IconButton,
  Button,
} from "@mui/material";
import { useTrainer } from "../../services/trainer.service";
import { useAuth } from "../../../../utils/auth/auth.guard";
import { Services } from "../../../shared/services";
import { BackIcon, DeleteIcon, AddIcon } from "../../../../assets/icons/Icons";
import { MuiButton } from "../../../shared/components/mui/button";
import { useNavigate } from "react-router-dom";

export function RecentChat({checked,setChecked}:any) {
  const trainer = useTrainer();
 
  const groupByOptions = [
    {
      title: "Personality Type",
      value: "personality_type",
    },
    {
      title: "Customer",
      value: "name",
    },

  ];

  const [chatGroups, setChatGroups] = React.useState<any>({
    onGoing: [],
    completed: [],
  });
  const [groupBy, setGroupBy] = React.useState(groupByOptions[0].value);
  
  const [accordionIndex, setAccordionIndex] = React.useState<any>(null);

  const AppService = React.useContext(Services.AppService);
  const auth = useAuth()!;
  const navigate = useNavigate();

  React.useEffect(() => {
    // console.log({ checked }, trainer.selectedChat)
  }, [])

  const groupChats = (showActive = false) => {
    // if (!trainer.chats.length) return;
    const filterGroupBy = function (array: any, key: any) {
      const groups = array.reduce((acc: any, element: any) => {
        (acc[element[key]] = acc[element[key]] || []).push(element);
        return acc;
      }, {});

      return Object.keys(groups)
        .sort()
        .map((title) => {
          return {
            title,
            chats: groups[title],
          };
        });
    };
    const obj = {
      completed: filterGroupBy(
        trainer.chats.filter((d: any) => d.status === "completed"),
        groupBy
      ),
      onGoing: filterGroupBy(
        trainer.chats.filter((d: any) => d.status === "ongoing"),
        groupBy
      ),
    };

    setChatGroups(obj);

    if (showActive || accordionIndex === null) {
      if (trainer.selectedChat) {
        setChecked(
          trainer.selectedChat
            ? trainer.selectedChat.status === "completed"
            : false
        );
        setAccordionIndex(
          obj[
            trainer.selectedChat.status === "completed"
              ? "completed"
              : "onGoing"
          ].findIndex((group: any) =>
            group.chats.find((v: any) => v === trainer.selectedChat)
          ) || 0
        );
      } else {
        setAccordionIndex(0);
      }
    }
  };

  const deleteChat = async (event: any, chat: any) => {
    event.stopPropagation();
    auth.setDialogDetails({
      title: "Deletion Confirmation",
      description: "Are you sure you want to delete this chat? ",
      successCB: () => {
        AppService.API_HANDLER({
          auth,
          requestMethod: "post",
          url: "https://api.vrt.zsservices.com/delete-conversation",
          payload: {
            id: chat.conversation_id, // "XMKZyD3GSFoPZATSLz4qC9Sm6PHkWe18Az37WDvWmMY9JvNMwd"                ,
          },
          useGlobalLoader: true,
          showErrorMessage: true,
          showSuccessMessage: false,
          fetchFromApi: true,
          LOCAL_RESPONSE: {
            status: "success",
            message:
              "...(optional) will be used as toast message for success / failed case",
            data: [],
          },
          successCb: (res: any) => {
            const chatIndex = trainer.chats.findIndex(
              (c: any) =>
              c.conversation_id === chat.conversation_id
              );
            if (chatIndex > -1) {
              if (trainer.selectedChat === trainer.chats[chatIndex]) {
                trainer.setSelectedChat(null)
              }
              trainer.chats.splice(chatIndex, 1);
              trainer.setChats([...trainer.chats]);
            }
          },
          failedCb: () => { },
        });
      },
    });
  };

  React.useEffect(() => {
    groupChats(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [trainer.chats]);

  React.useEffect(() => {
    groupChats();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [groupBy]);

  return (
    <Box className="chat-leftbar">
      <Box className="mb-3 main-view">
        <MuiButton
          variant="text"
          startIcon={<BackIcon />}
          onClick={() => navigate(-1)}
          className="back-link"
        >
          Back
        </MuiButton>
      </Box>
      <Box className="page-title mobile-view">
        <MuiButton
          variant="text"
          startIcon={<BackIcon />}
          onClick={() => navigate(-1)}
          className="back-link"
        >
        </MuiButton>
        My Conversations
        <Button className="new-btn" variant="text" endIcon={<AddIcon />} onClick={() => navigate("/dashboard/new-conversation")}>
          New Conversation
        </Button>
      </Box>
      <Box className="top-chat">
        <MuiSwitch
          startLabel="Ongoing Chats"
          endLabel="Evaluated Chats"
          checked={checked}
          onValueChange={(v) => {
            setChecked(v);
            setAccordionIndex(0);
          }}
        />
      </Box>
      <Box className="top-select">
        <span>Group By: </span>
        <FormControl>
          <Select
            value={groupBy}
            onChange={(e, v) => setGroupBy(e.target.value)}
            displayEmpty
            inputProps={{ "aria-label": "Without label" }}
          >
            {groupByOptions.map((option) => (
              <MenuItem key={option.value} value={option.value}>
                {option.title}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Box>
      <Box className="chat-container">
        {/* <>{console.log(chatGroups)}</> */}
        {chatGroups[checked ? "completed" : "onGoing"].length 
        ? chatGroups[checked ? "completed" : "onGoing"].map(
          (group: any, groupIndex: number) => (
            <Accordion
              key={groupIndex}
              expanded={accordionIndex === groupIndex}
              onChange={() =>
                setAccordionIndex((v: any) =>
                  v === groupIndex ? -1 : groupIndex
                )
              }
            >
              <AccordionSummary
                aria-controls="panel1d-content"
                id="panel1d-header"
              >
                <Typography>{group.title}</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Stack spacing={2}>
                  {group.chats.map((chat: any, i: number) => (
                    <Box
                      key={i}
                      className={
                        "chat-item " +
                        (trainer.selectedChat === chat ? "active" : "")
                      }
                      onClick={() => trainer.setSelectedChat(chat)}
                    >
                      <div
                        className="d-flex align-items-center flex-grow-1">
                        <Avatar
                          src={require(`../../../../assets/images/profile/${chat.image_id}.png`)}
                          sx={{ width: 32, height: 32 }}
                        />
                        <Box className="flex-grow-1 ms-3">
                          <Typography className="title">{chat.name}</Typography>
                          <small className="d-block">{chat.role}</small>
                          <Chip
                            className="personality_type"
                            label={chat.personality_type}
                            size="small"
                          />
                        </Box>
                        {/* <Box className="flex-grow-1 ms-3">
                          <small>Last Updated</small>
                        <Typography className="title">{chat.formatted_time}</Typography>
                        </Box> */}

                        <Box className="right-text">
                          <div className="chat-date">
                            <i>Last Updated</i>
                            <span>{chat.formatted_time}</span>
                          </div>
                          <IconButton className="delete" color="primary" onClick={(event) => deleteChat(event, chat)}>
                            <DeleteIcon />
                          </IconButton>
                        </Box>
                      </div>
                    </Box>
                  ))}
                </Stack>
              </AccordionDetails>
            </Accordion>
          )
        )
        : <Box className="mobile-view h-100 d-flex justify-content-center align-items-center flex-column text-center px-3 pb-5">
            <h4>
                                        Welcome {auth.userDetails?.firstName}!
                                    </h4>
                                    {/* <Typography component="span">No chats available</Typography> */}
                                    <Button  className="d-flex flex-column mt-1" variant="text" onClick={() => navigate("/dashboard/new-conversation")}>
                                        <span className="add-icon"></span>
                                        No chats available! Start a new conversation
                                    </Button>
            
          </Box>
        }
      </Box>
    </Box>
  );
}
