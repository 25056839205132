export const data = {
  status: "success",
  message: "success",
  data: [
    {
      title: "Overall",
      score: 6.25,
    },
    {
      title: "Tone",
      score: 70,
    },
    {
      title: "On-label recommendations",
      score: 10,
    },
    {
      title: "Appropriate team reference/handoff",
      score: 0,
    },
    {
      title: "Meets conversation objectives",
      score: 6.25,
    },
    {
      title: "Compliance flag",
      score: 0,
    },
  ],
};
