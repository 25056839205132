import React, { useEffect } from "react";
import { Services } from "../../../shared/services";
import { useAuth } from "../../../../utils/auth/auth.guard";

export function useSTT(on = require('./audio/on.mp3'), off = require('./audio/off.mp3')) {
  const AppService = React.useContext(Services.AppService);
  const auth = useAuth()!;
  const [transcript, setTranscript] = React.useState({ content: "" });
  const [listening, setListening] = React.useState("off");

  const [isRecording, setIsRecording] = React.useState(false);
  const [isLoading, setisLoading] = React.useState(false);
  const [audioBlob, setAudioBlob] = React.useState<any[] | null>(null);
  const mediaRecorderRef: any = React.useRef(null);

  useEffect(() => {
    sendAudioToBackend();
  },[audioBlob])

  const uuidv4 = () => {
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'
    .replace(/[xy]/g, function (c) {
        const r = Math.random() * 16 | 0, 
            v = c == 'x' ? r : (r & 0x3 | 0x8);
        return v.toString(16);
    });
}
  const startRecording = async () => {
    try {
      const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
      const mediaRecorder = new MediaRecorder(stream);
      const blobArr: any = []
      mediaRecorder.ondataavailable = (event: any) => {
        if (event.data.size > 0) {
          blobArr.push(event.data)
        }
      };
      mediaRecorder.onstop = (event: any) => {
        // on stop, stop mic recording
        stream.getAudioTracks().forEach(audioTrack => {
          audioTrack.stop()
        })
        setAudioBlob(blobArr);
      }
      mediaRecorderRef.current = mediaRecorder;
      mediaRecorderRef.current.start(1000);
      setIsRecording(true);
      new Audio(on).play();
        setListening("on");
    } catch (error) {
      console.error('Error accessing microphone:', error);
    }
  };
  const stopRecording = () => {
    if (mediaRecorderRef.current && mediaRecorderRef.current.state !== 'inactive') {
      mediaRecorderRef.current.stop();
      setIsRecording(false);
      new Audio(off).play();
      setListening("off");
    }
  };
  const sendAudioToBackend = async () => {
    try {
      if (audioBlob) {
        const formData: FormData = new FormData();
        const audioFileName = uuidv4()
        const audioFile = new Blob([...audioBlob], {type: 'audio/mp3'})
        formData.append('audio_file', audioFile, `${audioFileName}.mp3`);
        setisLoading(true);
        const response = await fetch('https://api.vrt.zsservices.com/get-synthesize-speech', {
          method: 'POST',
          body: formData,
          credentials: 'include'
        })
        response.json().then(res => {
          if (res.status === 'succeeded') {
            const transcript = res.data.split('\n')[0];
            setTranscript({ content: transcript });
          } else {
            console.error('Failed to send audio to the backend');
          }
          setisLoading(false);
        })
      }
    } catch (error) {
      console.error('Error sending audio to the backend:', error);
    } finally {
      setAudioBlob(null)
      setisLoading(false);
    }
  };

    return {
        transcript,
        listening,
        startRecording,
        stopRecording,
        isLoading
    }
}