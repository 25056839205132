import React from "react";
import './header.scss';
import { Box, AppBar, Toolbar, IconButton, Typography, Menu, MenuItem, Divider } from "@mui/material";
import { MenuIcon, UserIcon, LogoIcon } from "../../../../assets/icons/Icons";
import { Services } from "../../../shared/services";
import { useAuth } from "../../../../utils/auth/auth.guard";
import { useNavigate } from "react-router";

export function Header() {
    const AppService = React.useContext(Services.AppService);
    const auth = useAuth()
    const navigate = useNavigate();

    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

    const handleMenu = (event: React.MouseEvent<HTMLElement>) => {
        // console.log(event.currentTarget)
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {

        setAnchorEl(null);
        // auth.logout()
    };

    const handleLogout=()=>{
        handleClose()
        auth.logout()
    }

    return (
        <Box>
            <AppBar position="sticky">
                <Toolbar variant="dense">
                    <IconButton
                        size="large"
                        edge="start"
                        color="inherit"
                        aria-label="menu"
                        className="logo-btn"
                        onClick={() => navigate("/dashboard")}
                    >
                        <LogoIcon />
                    </IconButton>
                    <Typography variant="h6" component="div" className="flex-grow-1">
                        Virtual Sales Training
                    </Typography>
                  
                        <div className="user-profile">
                            <IconButton
                                size="large"
                                aria-label="account of current user"
                                aria-controls="menu-appbar"
                                aria-haspopup="true"
                                onClick={handleMenu}
                                color="inherit"
                            >
                                <UserIcon />
                                <Typography>{auth.userDetails?.firstName} {auth.userDetails?.lastName}</Typography>
                            </IconButton>
                            <Menu
                            id="menu-appbar"
                            anchorEl={anchorEl}
                            anchorOrigin={{
                                vertical: 'bottom',
                                horizontal: 'right',
                            }}
                            keepMounted
                            transformOrigin={{
                                vertical: 'top',
                                horizontal: 'right',
                            }}
                            open={Boolean(anchorEl)}
                            onClose={handleClose}
                        >
                            <MenuItem onClick={handleLogout}>Logout</MenuItem>
                        </Menu>
                    </div>
                      
                </Toolbar>
            </AppBar>
        </Box>
    )
}