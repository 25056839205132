import * as React from "react";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert, { AlertColor, AlertProps } from "@mui/material/Alert";
import { GlobalSnackbarProps } from "../../../utils/interfaces/interface";

export default function SimpleSnackbar(props: GlobalSnackbarProps) {
  const closeHandler = (_: any, reason: any) => {
    if (["timeout"].includes(reason)) {
      props.handleClose()
    }
  }
  const Alert = React.forwardRef<HTMLDivElement, AlertProps>(function Alert(
    props,
    ref
  ) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
  });

  return (
    <div>
      <Snackbar open={props.open} autoHideDuration={props.autoHideDuration} onClose={closeHandler} anchorOrigin={{ vertical: "top", horizontal: "right" }}>
        <Alert onClose={props.handleClose} severity={props.severity} sx={{ width: "100%" }}>
          {props.message}
        </Alert>
      </Snackbar>
    </div>
  );
}
