// /src/hooks/useAuth.tsx
import React, { useState, createContext, useContext } from "react";
import { TRANSLATE_LANGUAGE_DATA } from "../../../assets/data/json";

// Create the context 
const TrainerContext = createContext<any>(null);

export const TrainerProvider = ({ children }: { children: React.ReactElement }) => {

    const [chats, setChats] = useState<any>([]);
    const [selectedChat, setSelectedChat] = useState<any>(null);
    const [language, setLanguage] = React.useState('en');
   

    return (
        <TrainerContext.Provider
            value={{
                chats,
                setChats,
                selectedChat,
                setSelectedChat,
                translateData:TRANSLATE_LANGUAGE_DATA,
                language,
                setLanguage
            }}>
            {children}
        </TrainerContext.Provider>
    );
};

export const useTrainer = () => useContext(TrainerContext);