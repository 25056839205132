import { ButtonProps, Button, styled } from "@mui/material";
import React from "react";


export function MuiButton(props: ButtonProps) {

    const StyledButton = styled(Button)<ButtonProps>(({ theme }) => ({
        color: "#fff",
        textTransform: "none",
    }));

    return (
        <StyledButton {...props}>{props.children}</StyledButton>
    )
}