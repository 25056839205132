import React from "react";
import './login.scss';
import Button from "@mui/material/Button";
import { RightArrowIcon } from "../../../../assets/icons/Icons";

export default function Login() {

    const loginHandler = () => {
        const time = new Date(Date.now() + 60000)
        const expiryTiming = time.toUTCString();
        window.location.href = "https://idm.zsservices.com/Acadia/IdentityManager/app/Web/login.aspx?returnurl=https%3A%2F%2Fvrt.zsservices.com/login"
    }

    return (
        <React.Fragment>
        <div className="login-page">
            <h2>Virtual Sales Training</h2>
            <Button className="mt-3" onClick={loginHandler} variant="contained" endIcon={<RightArrowIcon />}>
                Login
            </Button>
            <div className="login-footer">
<a href="https://www.zs.com/zs-online-privacy-policy-for-hosted-applications" target="_blank"  rel="noreferrer noopener">Privacy Policy</a>
            </div>
        </div>
        </React.Fragment>
    )
}