export const data = {
  status: "success / failed",
  message:
    "...(optional) will be used as toast message for success / failed case",
  data: [
    // {
    //   label: "Time Frame",
    //   key: "time_frame",
    //   options: [
    //     {
    //       title: "Last 1 months",
    //       value: 1,
    //     },
    //     {
    //       title: "Last 3 months",
    //       value: 2,
    //     },
    //     {
    //       title: "Last 6 months",
    //       value: 3,
    //     },
    //     {
    //       title: "Last 9 months",
    //       value: 4,
    //     },
    //     {
    //       title: "Last 12 months",
    //       value: 5,
    //     },
    //   ],
    // },
    {
      label: "Customer",
      key: "customer_id",
      options: [
        {
          title: "Dr. Stephen Berg",
          value: 1,
        },
        {
          title: "Dr. David George",
          value: 2,
        },
        {
          title: "Dr. Sophia Greene",
          value: 3,
        },
      ],
    },
    {
      label: "Difficulty",
      key: "difficulty",
      options: [
        {
          title: "Easy",
          value: "1",
        },
        {
          title: "Medium",
          value: "2",
        },
        {
          title: "Hard",
          value: "3",
        },
        {
          title: "Random",
          value: "Random",
        },
      ],
    },
  ],
};
