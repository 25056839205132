import React from 'react';
import { Navigate } from 'react-router-dom';
import { LoginModuleRoutes } from './modules/login';
import { TrainerModuleRoutes } from './modules/trainer';
import RouterModule from './utils/auth/router.module';
import { AppRoutes } from './utils/enums/enum';
import Protected from 'utils/auth/route.guard';

export const AppModuleRoutes = () => {
	return (
		<Protected>
			<RouterModule routes={
				[
					{
						path: "/",
						element: <Navigate to={`/${AppRoutes.UserDashbaord}`} />
					},
					{
						path: `/${AppRoutes.Login}/*`,
						element: <LoginModuleRoutes /> // TODO: Login page can be used directly
					},
					{
						path: `/${AppRoutes.UserDashbaord}/*`,
						element: <TrainerModuleRoutes />,
					},
					{
						path: "*",
						element: <Navigate to={`/${AppRoutes.UserDashbaord}`} />
					}
				]
			} />
		</Protected>
	)
}