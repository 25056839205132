import React from "react";
import "./chat.scss";
import {
  Box,
  IconButton,
  Typography,
  Button,
  Avatar,
  TextareaAutosize,
} from "@mui/material";
import {
  ClientChat,
  SendIcon,
  UserIcon,
  MicIcon,
  PlayAudioIcon,
  PauseAudioIcon,
  LoadAudioIcon,
} from "../../../../assets/icons/Icons";
import { Services } from "../../../shared/services";
import { useSTT } from "../chat-window/speechToText";
import { useAuth } from "../../../../utils/auth/auth.guard";
import { useTrainer } from "../../services/trainer.service";
import { audioSpeed, femaleVoices, maleVoices, notifications } from "../../../../utils/enums/enum";


export function Chat({checked,  setChecked}: any) {
  const AppService = React.useContext(Services.AppService);
  const auth = useAuth()!;
  const trainer = useTrainer();

  const [loading, setLoading] = React.useState(false);
  const [message, setMessage] = React.useState("");
  const [conversation, setConversation] = React.useState<any[] | null>(null);
  const [spokenIndex, setSpokenIndex] = React.useState(-1);
  const [spokenWords, setSpokenWords] = React.useState<string[]>([]);
  const [speaking, setSpeaking] = React.useState<boolean>(false);
  const [newResponse, setNewResponse] = React.useState<boolean>(false);
  const [playAudio, setPlayAudio] = React.useState<boolean>(true);
  const [audioBeingPlayed, setAudioBeingPlayed] = React.useState<any | null>(null);
  const [audioPlayingIndex, setAudioPlayingIndex] = React.useState<number | null>(-1);
  const [speed, setSpeed] = React.useState<number | null>(audioSpeed);

  const messagesEndRef = React.useRef<any | null>(null);

  const { startRecording, stopRecording, transcript, listening, isLoading } = useSTT();

  const speechSynthesisRef = React.useRef<SpeechSynthesis | null>(null);

  React.useEffect(() => {
    if (spokenIndex !== -1) {
      if (conversation) {
        speakText(conversation[spokenIndex].content, spokenIndex);
      }
    }
  }, [spokenIndex]);

  React.useEffect(() => {
    // Initialize SpeechSynthesis when the component mounts
    speechSynthesisRef.current = window.speechSynthesis;
    // Add event listener for visibilitychange to stop text-to-speech
    const handleVisibilityChange = () => {
      if (document.visibilityState === "hidden") {
        // Clean up resources when the window is not visible
        stopTextToSpeech();
      } else {
        // Remove highlight when the tab becomes visible again
        setConversation((prevConversation) =>
          prevConversation?.map((chat) => ({
            ...chat,
            content: removeHighlight(chat.content),
          })) ?? []
        );
      }
    };
    audioBeingPlayed?.pause()
    setAudioBeingPlayed(null)
    window.addEventListener("visibilitychange", handleVisibilityChange);
    if (trainer.selectedChat) {
      loadChatHistory();
    }
    return () => {
      // Cleanup SpeechSynthesis and remove event listener when the component unmounts
      stopTextToSpeech();
      window.removeEventListener("visibilitychange", handleVisibilityChange);
    };
  }, [trainer.selectedChat]);

  React.useEffect(() => {
    if (transcript.content)
      setMessage((v) => v + (v[-1] === " " ? "" : " ") + transcript.content);
  }, [transcript]);
  
  React.useEffect(() => {
    if(!playAudio && audioBeingPlayed) {
      audioBeingPlayed.pause();
      setSpokenIndex(-1);
      setPlayAudio(true)
      setConversation((prevConversation) => {
        const newConversation = (prevConversation ?? []).map((chat,i) => ({
          ...chat,
          audioIcon: 'play'
        }));
        return newConversation;
      });
    }
  },[audioBeingPlayed,playAudio])


  const scrollToBottom = (behavior: "instant" | "smooth" = "smooth") => {
    setTimeout(() => {
      messagesEndRef.current?.scrollIntoView({ behavior });
    }, 50);
  };

  const sendMessage = async () => {
    if (!message.trim() || loading) return;
    const setMsg = (role: "user" | "assistant", content: string) => {
      setConversation((v) => {
        v!.push({
          role,
          content,
          audioIcon: 'play'
        });
        return v!.slice();
      });
    };
    setMsg("user", message);
    scrollToBottom();
    setLoading(true);
    AppService.API_HANDLER({
      auth,
      requestMethod: "post",
      url: "https://api.vrt.zsservices.com/chat",
      payload: {
        // "userId": "123",
        id: trainer.selectedChat.conversation_id,
        content: message,
      },
      useGlobalLoader: false,
      showErrorMessage: true,
      showSuccessMessage: false,
      fetchFromApi: true,
      LOCAL_RESPONSE: {
        status: "success",
        message:
          "...(optional) will be used as toast message for success / failed case",
        data: {
          chats: {
            content:
              "I am looking for a mattress that is comfortable and provides good support for my back. Do you have any options that meet these requirements?",
          },
        },
      },
      successCb: (res: any) => {
        setLoading(false);
        setMsg("assistant", res.data.chats.content);
        // speak(res.data.chats.content);
        setNewResponse(true);
        setSpokenIndex(conversation?.length || 0);
        scrollToBottom();
      },
      failedCb: () => {
        setLoading(false);
      },
    });
    setMessage("");
  };

  const loadChatHistory = async () => {
    AppService.API_HANDLER({
      auth,
      requestMethod: "post",
      url: "https://api.vrt.zsservices.com/chat-details",
      payload: {
        id: trainer.selectedChat.conversation_id, // "XMKZyD3GSFoPZATSLz4qC9Sm6PHkWe18Az37WDvWmMY9JvNMwd"                ,
      },
      useGlobalLoader: false,
      showErrorMessage: true,
      showSuccessMessage: false,
      fetchFromApi: true,
      LOCAL_RESPONSE: 
          {
            "status": "success",
            "message": "success",
            "data": {
                "chats": [
                    {
                        "role": "system",
                        "content": "You have been successful in setting up a conversation with Dr. Stephen Berg. Dr. Berg is a clinically focused neurologist, who cares about the efficacy of the drugs he prescribes and treatment outcomes achieved by his patients. While Dr. Berg has multiple patients on Daybue, he also has many patients in practice that are still not on therapy. The Goal of the conversation is to get a favorable view of the product from him."
                    }
                ]
            }
      },
      successCb: (res: any) => {
        setConversation(res.data.chats.map((chat: any) => { return { ...chat, audioIcon: 'play' } }));
        scrollToBottom();
      },
      failedCb: () => {
        setConversation([]);
      },
    });
  };

  const chatEvaluate = async () => {
    // trainer.setChats((v: any) => [...v]);
    auth.setDialogDetails({
      title: "Submission Confirmation",
      description: "Are you sure you want to end this chat? ",
      successCB: () => {
        auth.setLoading(true);
        let notificationIndex: number = 0;
        let evaluationInProgress: boolean = true;
        let intervalId: any = null;

        const openSnackbar = (msg: string, duration: number = 5000, type: string = 'info') => {
          auth?.setSnackBarDetails({
            message: msg,
            autoHideDuration: duration,
            type: type,
          });
          notificationIndex = (notificationIndex + 1)%(notifications.length)
        }
        const notificationMessagesForEvaluate = (openSnackbarCb: Function) => {
          openSnackbarCb(notifications[notificationIndex])
          intervalId = setInterval(() => {
            openSnackbarCb(notifications[notificationIndex])
            // console.log({evaluationInProgress, notificationIndex})
            if(!evaluationInProgress) {
              notificationIndex = 0;
              clearInterval(intervalId);
            }
          }, 6000); // interval: 6 seconds
        };
        notificationMessagesForEvaluate(openSnackbar)
        const handleEvaluationCompletion = () => {
          trainer.selectedChat.status = "completed";
          setChecked(true)
          trainer.setChats((v: any) => [...v]);
          auth.setLoading(false);
          clearInterval(intervalId);
          auth?.setSnackBarDetails({
            message:'Evaluation completed',
            type: "success",
          });
          evaluationInProgress = false;
        }
        AppService.API_HANDLER({
          auth,
          requestMethod: "post",
          url: "https://api.vrt.zsservices.com/evaluate",
          payload: {
            id: trainer.selectedChat.conversation_id, // "XMKZyD3GSFoPZATSLz4qC9Sm6PHkWe18Az37WDvWmMY9JvNMwd"
            // env: 'dev',
            // customer_id: trainer.selectedChat.customer_id,
            // language:trainer.language
          },
          useGlobalLoader: false,
          showErrorMessage: true,
          showSuccessMessage: false,
          fetchFromApi: true,
          LOCAL_RESPONSE: {
            status: "success",
            message:
              "...(optional) will be used as toast message for success / failed case",
            data: [],
          },
          successCb: (res: any) => {
            handleEvaluationCompletion()
            
          },
          failedCb: () => {
            auth.setLoading(false);
            evaluationInProgress = false;
          },
        });
      },
    });
  };

  const removeHighlight = (text: string): string => {
    return text.replace(/<\/?span.*?>/g, ''); // Remove HTML tags for highlighting
  };

  const stopTextToSpeech = () => {
    
      setConversation((prevConversation) => {
        const newConversation = (prevConversation ?? []).map((chat) => ({
          ...chat,
          content: chat.content,
        }));
        return newConversation;
      });
      if (conversation) {
        setSpokenIndex(-1);
      }
    
  };

  const changeAudioIconForClickedChat = (icon: string, index: number) => {
    setConversation((prevConversation) => {
      const newConversation = (prevConversation ?? []).map((chat, i) => ({
        ...chat,
        audioIcon: i === index ? `${icon}` : 'play'
      }));
      return newConversation;
    });
  }

  const selectVoice = (index: number) => {
    let speechVoice: string;
    if (index % 2 === 0) {
      if (trainer.selectedChat.image_id.includes('F')) {
        const femaleVoiceIndex = trainer.selectedChat.image_id.split('F')[1]
        speechVoice = femaleVoices[(femaleVoiceIndex - 1) % (femaleVoices.length - 1)]
      } else {
        const maleVoiceIndex = trainer.selectedChat.image_id.split('M')[1]
        speechVoice = maleVoices[(maleVoiceIndex - 1) % (maleVoices.length - 1)]
      }
    } else {
      if (auth.userDetails?.gender == 1) {
        speechVoice = femaleVoices[(femaleVoices.length - 1)]
      } else {
        speechVoice = maleVoices[(maleVoices.length - 1)]
      }
        
    }
    return speechVoice
  }

  const speakText = async (text: string, index: number) => {
    if (audioBeingPlayed && audioPlayingIndex === index) {
      audioBeingPlayed.play();
      changeAudioIconForClickedChat('pause', index)
    } else {
      changeAudioIconForClickedChat('load', index)
      const speechVoice = selectVoice(index)
      setSpokenIndex(-1); // Reset the spokenIndex initially
      try {
        const audios: any = []
        const tempSentences = text.split('.').filter((sentence) => sentence);
        let sentences: string[] = []
        for(let index = 0; index< tempSentences.length; index++) {
          const sentence = tempSentences[index];
          if (sentence.toLocaleLowerCase().includes('dr')) {
            sentences.push([sentence, tempSentences[index+1]].join('. '))
            index++;
          } else {
            sentences.push(sentence);
          }
        }
        // console.log({sentences, tempSentences})
        // tempSentences.forEach((sentence: string) => {
        // })
        let audioIndex = 0;
        const playNextAudio = () => {
          if ((audioIndex + 1) < sentencesLength) {
            // Check if the audio has come from backend yet.
            if (audios[audioIndex + 1]) {
              audioIndex += 1;
              audios[audioIndex].play();
              setAudioBeingPlayed(audios[audioIndex])
            }
            // If the audio is still not present, try to play the audio after 0.1 second wait.
            else {
              setTimeout(playNextAudio, 100)
            }
          } else {
            setPlayAudio(true);
            setConversation((prevConversation) => {
              const newConversation = (prevConversation ?? []).map((chat, i) => ({
                ...chat,
                audioIcon: 'play'
              }));
              return newConversation;
            });
            setAudioBeingPlayed(null);
            setAudioPlayingIndex(-1);
          }
        }
        const sentencesLength = sentences.length;
        for (let sentenceIndex = 0; sentenceIndex < sentencesLength; sentenceIndex++) {
            const sentence: string = sentences[sentenceIndex];
            const requestBody = {
              input: sentence,
              voice: speechVoice,
              speed: speed
            }
            const audio = new Audio('data:audio/wav;base64,UklGRigAAABXQVZFZm10IBIAAAABAAEARKwAAIhYAQACABAAAABkYXRhAgAAAAEA')
            audio.autoplay = true;
            audio.play()
            await AppService.API_HANDLER({
              auth,
              requestMethod: "post",
              url: "https://api.vrt.zsservices.com/get-speech",
              payload: requestBody,
              useGlobalLoader: false,
              showErrorMessage: false,
              showSuccessMessage: false,
              fetchFromApi: true,
              LOCAL_RESPONSE: {},
              successCb: async (response: any) => {
                const audioData = await response.body;
                const audioUrl = `data:audio/mp3;base64,${audioData}`;
                // const audio= new Audio();
                audio.src = audioUrl
                audio.autoplay = false;
                audios.push(audio)
                if (sentenceIndex === 0) {
                  audios[sentenceIndex].play();
                  changeAudioIconForClickedChat('pause', index)
                  setAudioBeingPlayed(audios[sentenceIndex])
                  setAudioPlayingIndex(index);
                }
                audios[sentenceIndex].addEventListener('ended', playNextAudio);
              },
              failedCb: (res: any) => {
                  console.log(res)
              }
          })
        }
      } catch (error) {
        console.error('Error synthesizing speech:', error);
        changeAudioIconForClickedChat('play', index)
      }
    }
  };

  const handleHeadphoneClick = (index: number) => {
    const isAudioAlreadyPlaying: boolean = conversation?.filter(chat => chat.audioIcon !== 'play').length !== 0
    if (conversation && !isAudioAlreadyPlaying) {
      if (conversation[index].audioIcon === 'play') {
        if (conversation && conversation[index]) {
          if (index !== spokenIndex) {
            setSpokenIndex(index);
          } else {
            if (speechSynthesisRef.current) {
              speechSynthesisRef.current.cancel();
              setSpokenIndex(-1);
            }
          }
        }
      }
    }
    if (conversation && conversation[index].audioIcon === 'pause') {
      setPlayAudio(false);
    }
  }

  return (
    <>
      {trainer.selectedChat ? (
        <Box className="d-flex flex-column flex-grow-1 h-100">
          {conversation ? (
            <>
              <Box className="chatting-window mb-auto">
                {conversation.map((chat, i) => (
                  <Box
                    key={i}
                    className={
                      "chat " + (chat.role === "user" ? "chat-right" : "")
                    }
                  >
                    {chat.role === "user" ? <UserIcon /> : <Avatar
                      src={require(`../../../../assets/images/profile/${trainer.selectedChat.image_id}.png`)}
                      sx={{ width: 32, height: 32 }}
                    />}
                    <div className="content">
                      <div className="text">
                      <Typography
                          component="p"
                        >{chat.content}
                      </Typography>
                        {chat.audioIcon === 'play' ?
                          (<PlayAudioIcon
                            className="headphone"
                            onClick={() => handleHeadphoneClick(i)}
                          />) :
                          chat.audioIcon === 'pause' ?
                            (<PauseAudioIcon
                              className="headphone"
                              onClick={() => handleHeadphoneClick(i)}
                            />) :
                            chat.audioIcon === 'load' ?
                              (<div className="headphone-loader">
                                <LoadAudioIcon
                                onClick={() => handleHeadphoneClick(i)}
                              /></div>) : <></>
                        }
                      </div>
                    </div>
                  </Box>
                ))}
                {loading ? (
                  <Box className="chat">
                    <ClientChat />
                    <div className="content">
                      <div className="text">
                        <Typography component="p" className="loader">
                          <span className="dot"></span>
                          <span className="dot"></span>
                          <span className="dot"></span>
                        </Typography>
                      </div>
                    </div>
                  </Box>
                ) : null}
                <div
                  ref={(containerRef) =>
                    (messagesEndRef.current = containerRef)
                  }
                />
              </Box>
              <Box className="bottom-section">
              {trainer.selectedChat.status === "ongoing" ? (
                <>
                  <Box className="bottom-btn">
                    {/* <Button variant="outlined">Save</Button> */}
                    <Button
                      variant="contained"
                      className="ms-3"
                      onClick={() => chatEvaluate()}
                    >
                      End & Evaluate
                    </Button>
                  </Box>

                  <div className="d-flex justify-content-between chatbar">
                    <IconButton
                      className="mic-button"
                      aria-label="speech"
                      onClick={() => {
                        !isLoading && (listening === 'off' ? startRecording() : stopRecording());
                      }}
                    >
                      {isLoading ? <div className="mic-loader"></div> : ''}
                      <MicIcon className={listening} />
                      {listening !== 'off' ? (
                        <>
                          <div className="pulse-ring"></div>
                          <div className="pulse-ring delay"></div>
                        </>
                      ) : null}
                    </IconButton>
                    <div className="chat-area">
                      <TextareaAutosize
                        minRows={1}
                        maxRows={10}
                        className="flex-grow-0"

                        autoComplete="off"
                        value={message}
                        onKeyUp={(e) => e.nativeEvent.key === 'Enter' && sendMessage()}
                        onChange={(e) => setMessage(e.target.value)}
                        placeholder="Type your response here |"
                        style={{ resize: 'none' }} // Prevent textarea resizing by user
                      />
                    </div>
                    <IconButton aria-label="send message" onClick={() => sendMessage()}>
                      <SendIcon />
                    </IconButton>
                  </div>



                </>
              ) : null}
              </Box>
            </>
          ) : null}
        </Box>
      ) : null}
    </>
  );
}
