import { createTheme } from '@mui/material';

export const theme = createTheme({
    palette: {
        mode: 'dark',
        divider: '#3E3E3E',
        primary: {
            main: '#C94890',
            contrastText: '#fff',
        },
        secondary: {
            main: '#E33E7F',
            contrastText: '#fff',
        },
        background: {
            default: "#202020",
        }
    },
    typography: {
        fontSize: 14,
    },
})