import React from 'react';
import {
	NewConversation,
	Conversation,
	Dashboard,
	UserDashbaord
} from "./index"
import RouterModule from '../../utils/auth/router.module';
import { Navigate } from 'react-router-dom';
import Protected from '../../utils/auth/route.guard';
import { NewUserDashboard } from './components/user-dashboard/dashbaord';
import UserDashboard from './pages/user-dashboard/user-dashbaord';



export const routes = () => {
	return (
		<Protected>
			<RouterModule routes={
		[
			{
				path: "",
				element: <UserDashboard />,
				children: [
					{
						path: "",
						element: <NewUserDashboard />
					},
					{
						path: "chats",
						element: <Conversation />
					},
					{
						path: "new-conversation",
						element: <NewConversation />
					},
					{
						path: ":conversationId",
						element: <Conversation />
					},
					{
						path: "report",
						element: <NewUserDashboard />
					},
					{
						path: "*",
						element: <Navigate to={`/dashboard`} />
					}
				]
			},
		]
	} />
		</Protected>
	)
}