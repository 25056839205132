import { Grid, Switch, SwitchProps, styled } from "@mui/material";
import React from "react";


export function MuiSwitch({
    startLabel,
    endLabel,
    onValueChange,
    ...props
}: {
    startLabel?: string,
    endLabel?: string,
    checked: boolean,
    onValueChange?: ((checked: boolean) => void),
} & SwitchProps) {

    const StyledSwitch = styled(Switch)(() => ({
        padding: 1,
        '& .MuiSwitch-track': {
            background: "linear-gradient(180deg, #DF684A 0%, #CA498D 100%)",
            borderRadius: 11,
            opacity: 1
        },
        '& .MuiSwitch-switchBase.Mui-checked': {
            '& + .MuiSwitch-track': {
                opacity: 1,
            },
            '&.Mui-disabled + .MuiSwitch-track': {
                opacity: 0.5,
            },
        },
        '& .MuiSwitch-thumb': {
            background: "#FFFFFF",
            boxShadow: '0px 4px 4px 0px #00000040',
            width: 10,
            height: 10,
            margin: 2,
        },
    }));

    return (
        <Grid component="div" container alignItems="center" spacing={1}>
            <Grid item style={{ opacity: props.checked ? 0.5 : 1 }}>{startLabel}</Grid>
            <Grid item>
                <StyledSwitch
                    {...props}
                    checked={props.checked}
                    onChange={(_, v) => {
                        onValueChange && onValueChange(v)
                    }}
                />
            </Grid>
            <Grid item style={{ opacity: props.checked ? 1 : 0.5 }}>{endLabel}</Grid>
        </Grid>
    )
}