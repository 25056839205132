import React from 'react';
import { Route, Routes } from 'react-router-dom';

export default function RouterModule({ routes }: { routes: any }) {

    const getRoutes = (routes: any) => {
        return <>
            {
                routes.map((d: any, i: number) =>
                    <Route path={d.path} element={d.element} key={i} >
                        {
                            d.children
                                ? (
                                    <>
                                        {
                                            getRoutes(d.children)
                                        }
                                    </>
                                )
                                : null
                        }
                    </Route >
                )
            }
        </>
    }

    return (
        <Routes>
            {
                getRoutes(routes)
            }
        </Routes>
    );
}