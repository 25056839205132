export default class CommonService {
  private httpManager: any;

  public theme = true;
  constructor(http: any) {
    this.httpManager = http;
  }

  public API_HANDLER = async ({
    auth,
    url,
    requestMethod,
    LOCAL_RESPONSE,
    successCb,
    failedCb,
    payload = {},
    showSuccessMessage = true,
    showErrorMessage = true,
    useGlobalLoader = true,
    fetchFromApi = false,
  }: {
    url: string;
    auth: any;
    requestMethod: "get" | "post";
    fetchFromApi: boolean;
    LOCAL_RESPONSE: any;
    payload?: string | number | any | unknown;
    successCb?: Function | undefined;
    failedCb?: Function | undefined;
    showErrorMessage?: boolean;
    showSuccessMessage?: boolean;
    useGlobalLoader?: boolean;
  }) => {
    const errorHandler = (message: string) => {
      if (useGlobalLoader) {
        auth?.setLoading(false);
      }
      if (showErrorMessage) {
        auth?.setSnackBarDetails({
          message,
          type: "error",
        });
      }
      if (typeof failedCb === "function") {
        failedCb();
      }
    };

    const responseHandler = (res: any) => {
      if (res.status === "failed") {
        errorHandler(String(res?.message || "Something went wrong..."));
      } else {
        if (typeof successCb === "function") {
          successCb(res);
        }
        if (useGlobalLoader) {
          auth?.setLoading(false);
        }
        if (showSuccessMessage && res.message) {
          auth?.setSnackBarDetails({
            message: res.message,
            type: "success",
          });
        }
      }
    };

    // console.log("**** New Request ****");
    // console.log("Request Type:", requestMethod);
    // console.log("Endpoint:", url);
    // console.log("Request Payload:");
    // console.log(JSON.stringify(payload, null, 4));
    try {
      if (useGlobalLoader) {
        auth?.setLoading(true);
      }
      if (auth?.API_MODE && fetchFromApi) {
        responseHandler(await this.httpManager[requestMethod](url, payload));
      } else {
        setTimeout(
          () => responseHandler(JSON.parse(JSON.stringify(LOCAL_RESPONSE))),
          auth?.LOCAL_RESPONSE_TIMEOUT
        );
      }
    } catch (e: any) {
      errorHandler(e.message ? e.message : "Something went wrong..!");
      if (e.status === 403 || e.status === 401) {
        // console.log(window.location.origin);
        window.location.href = window.location.origin + "/login";
        // "https://idm.zsservices.com/Acadia/IdentityManager/app/Web/logout.aspx";
      }
    }
  };
}
