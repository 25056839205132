import React, { MouseEvent } from "react";
import './radial-bar-chart.scss';
import { Box, CircularProgress, Stack } from "@mui/material";
import { reportsScoreValues } from "utils/enums/enum";
import { useTrainer } from "modules/trainer/services/trainer.service";

export function RadialBarChart({
    data,
    size,
}: { data: { title: string, score: number, color: string }[], size: number }) {


    const width = size < 250 ? 250 : size;
    const height = width / 2;
    const adj = 40;
    const ANGLE = 90;
    const totalScore = data.reduce((old, curr) => old += curr.score, 0) / data.length;
    const padding = (() => {
        const p = (width - adj * 2) / (data.length * 2 - 1)
        return (width - adj * 2 - p / 2) / (data.length * 2 - 1)
    })();

    const ref = React.useRef<SVGSVGElement | null>(null);
    const trainer = useTrainer()

    function polarToCartesian(centerX: number, centerY: number, radius: number, angleInDegrees: number) {
        var angleInRadians = (angleInDegrees - 90) * Math.PI / 180.0;
        return {
            x: centerX + (radius * Math.cos(angleInRadians)),
            y: centerY + (radius * Math.sin(angleInRadians))
        };
    }

    function describeArc(radius: number, endAngle: number = ANGLE, startAngle: number = -ANGLE, x: number = height, y: number = height) {
        var start = polarToCartesian(x, y, radius, endAngle);
        var end = polarToCartesian(x, y, radius, startAngle);
        var largeArcFlag = endAngle - startAngle <= 180 ? "0" : "1";
        var d = [
            "M", start.x, start.y,
            "A", radius, radius, 0, largeArcFlag, 0, end.x, end.y
        ].join(" ");
        return d;
    }

    React.useEffect(() => {
        function mouseenter(event: Event) {
            const paths = ref.current?.querySelectorAll(".progress")!;
            for (let i = 0; i < paths.length; i++) {
                paths.item(i)?.setAttribute("stroke-opacity", "0.3")
            }
            (event.target as HTMLElement)?.setAttribute("stroke-opacity", "1")
        }
        function mouseleave(event: Event) {
            const paths = ref.current?.querySelectorAll(".progress")!;
            for (let i = 0; i < paths.length; i++) {
                paths.item(i)?.setAttribute("stroke-opacity", "1")
            }
        }
        const wrapper = ref.current?.querySelector(".progress-wrapper");

        const paths = ref.current?.querySelectorAll(".progress")!;
        for (let i = 0; i < paths.length; i++) {
            paths.item(i)?.remove();
        }
        for (let i = 0; i < data.length; i++) {
            const track = document.createElementNS('http://www.w3.org/2000/svg', 'path');
            track.setAttribute('d', describeArc(height - (i * padding) - padding / 2));
            track.setAttribute("stroke-width", `${padding / 2}`);
            track.classList.add("track");

            const progress = document.createElementNS('http://www.w3.org/2000/svg', 'path');
            progress.setAttribute('d', describeArc(height - (i * padding) - padding / 2, ((ANGLE * 2) * data[i].score / 100) - ANGLE));
            progress.classList.add("progress");
            const length = progress.getTotalLength()!;
            progress.setAttribute("stroke", `${data[i].color}`);
            progress.setAttribute("stroke-width", `${padding / 2}`);

            // progress.setAttribute("stroke-dashoffset", `${-length}`);
            // progress.setAttribute("stroke-dasharray", `${length}`);

            wrapper?.appendChild(track);
            wrapper?.appendChild(progress);


            progress.addEventListener("mouseenter", mouseenter);
            progress.addEventListener("mouseleave", mouseleave);

            setTimeout(() => {
                ref.current?.querySelector(".niddle")?.setAttribute("transform", `translate(${height}, ${height}) rotate(${totalScore / 100 * ANGLE * 2} 0 0)`);
                progress.setAttribute("stroke-dashoffset", "0");
            }, 1000);
        }
        return () => {
            // const paths: NodeList = ref.current?.querySelectorAll(".progress")!;
            // for (let i = 0; i < paths.length; i++) {
            //     paths.item(i)?.removeEventListener("mouseenter", mouseenter);
            //     paths.item(i)?.removeEventListener("mouseleave", mouseleave);
            // }
        }
    }, [])

    return (
        <Box >
            <Box className="radial-bar-chart">
                <svg ref={(containerRef) => (ref.current = containerRef)} width={width} height={height + adj} viewBox={`0 0 ${width} ${height}`} fill="none" xmlns="http://www.w3.org/2000/svg">
                    <g className="progress-wrapper"></g>
                    <g className="niddle" pointerEvents={"none"} transform={`translate(${height}, ${height}) rotate(0 0 0)`}>
                        <path d={`M0 -4, L0 4, L -${height} 0`} fill="white" />
                        <circle r="10" fill="#232222" strokeWidth={2} stroke={"#FFF"} />
                    </g>
                </svg >
            </Box>
            <Box>
                {
                    data.map((v, i) => (
                        <Stack key={i} className="labels">
                            <span style={{ backgroundColor: v.color }}></span>
                            {v.title} <small>{
                                trainer.language == 'jp' ? 
                                    (`${v.score}` === `${Math.round(+v.score)}`
                                    ? v.score 
                                    : (+v.score).toFixed(2)) + '%'
                                :
                                    +(`${v.score}` === `${Math.round(+v.score)}`
                                    ? v.score
                                    : (+v.score).toFixed(2)) < 60 ? reportsScoreValues[0] : +(`${v.score}` === `${Math.round(+v.score)}`
                                    ? v.score
                                    : (+v.score).toFixed(2)) < 80 ? reportsScoreValues[1] : reportsScoreValues[2]
                            }</small>
                        </Stack>
                    ))
                }
            </Box>
        </Box>
    )
}