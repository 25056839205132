import React from "react";
import { Outlet } from "react-router-dom";
import Box from "@mui/material/Box/Box";
import { TrainerProvider } from "../../services/trainer.service";
import { Header } from "../../components/header/header";

export default function UserDashboard() {
    return (
        <TrainerProvider>
            <Box className="d-flex flex-column h-100">
                <Header />
                <Box sx={{ flexGrow: 1 }}>
                    <Outlet />
                </Box>
            </Box> 
        </TrainerProvider>

    )
}