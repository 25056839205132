import * as React from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import { Stack } from "@mui/material";
import { GlobalConfirmationDialogProps } from "../../../utils/interfaces/interface";
import { CloseIcon } from "../../../assets/icons/Icons";
const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

export default function ConfirmationDialog(props: GlobalConfirmationDialogProps) {
  return (
    <div>
      <Modal
        open={props.open}
        onClose={() => {
          props.failedCB && props.failedCB();
          props.close()
        }}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        className="confirm-modal"
      >
        <Box sx={style} className="modal-content">
          <Stack dir="row">
            {/* <div className="modal-top">
              <Typography id="modal-modal-title" variant="h6" component="h2">
                {props.title}
              </Typography>
              <Button variant="text" onClick={() => props.close()}><> <CloseIcon /> </></Button>
            </div> */}
            <div className="content">
              <Typography>
                {props.description}
              </Typography>
            </div>
          </Stack>
          <div className="modal-bottom">
            {
              props.isAlert
                ? (
                  <Button onClick={() => props.close()} className="common-button" variant="outlined">Ok</Button>
                )
                : (
                  <>
                    <Button onClick={() => {
                      props.failedCB && props.failedCB();
                      props.close()
                    }} className="common-button reset-btn me-1">No</Button>
                    <Button onClick={() => {
                      props.successCB && props.successCB();
                      props.close()
                    }} className="common-button" variant="outlined">
                      Yes
                    </Button>

                  </>
                )
            }
          </div>
        </Box>
      </Modal>
    </div>
  );
}
